<script setup>
import { computed, inject, onMounted, ref, watch } from "vue";
import TheHomeDummy from "@/views/TheHomeDummy.vue";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";
import AttemptSelectorPublicExamination from "@/components/attempt-selector/AttemptSelectorPublicExamination.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import AttemptSelectorType from "@/components/attempt-selector/AttemptSelectorType.vue";
import AttemptSelectorSettings from "@/components/attempt-selector/AttemptSelectorSettings.vue";
import { useWS } from "@/components/composables/ws-call.js";
import MainRepository from "@/repositories/MainRepository.js";
import { useRouter } from "vue-router";
import {
  ATTEMPT_BY_GENERAL_PROCESAL,
  ATTEMPT_CUSTOM,
  ATTEMPT_CUSTOM_BLANK,
  ATTEMPT_CUSTOM_FAVOURITES,
  ATTEMPT_CUSTOM_INCORRECT,
  ATTEMPT_CUSTOM_UNCERTAIN,
} from "@/components/utils.js";
import AttemptSelectorExam from "@/components/attempt-selector/AttemptSelectorExam.vue";
import AttemptSelectorReview from "@/components/attempt-selector/AttemptSelectorReview.vue";
import { useToast } from "@/components/composables/notifications.js";
import AttemptSelectorSteps from "@/components/attempt-selector/AttemptSelectorSteps.vue";
import AttemptSelectorInsuficientQuestionsDialog from "@/components/attempt-selector/AttemptSelectorInsuficientQuestionsDialog.vue";

const { callWs, record } = useWS(MainRepository.attemptInProgress);
const { callWs: callExamStatistics, record: examStatistics } = useWS(MainRepository.examStatistics());
const {
  callWs: callNewAttempt,
  record: newAttemptRecord,
  error: newAttemptError,
  // isLoading: creatingNewAttempt,
} = useWS(MainRepository.newAttempt());
const { callWs: callNewAttemptInfo, record: newAttemptInfoRecord } = useWS(MainRepository.newAttemptInfo());

const { sendServerError, sendNotification, sendError, sendWarnNotification } = useToast();

const router = useRouter();

const user = inject("user");
const publicExaminations = inject("publicExaminations");
const appLoading = inject("appLoading");
const selectedPublicExamination = ref(null);
const selectedType = ref(null);
const selectedExam = ref(null);
const selectedDifficulty = ref(null);
const selectedSubtype = ref("ambas");
const nQuestions = ref(20);
const selectedContents = ref(null);
const step = ref(1);
const dialogOpen = ref(false);
const buttonCreatedDisabled = ref(false);

const without_review = ref({
  questions: null,
  tests: null,
  practical: null,
  practicalOfficial: null,
});

const isLoading = computed(() => {
  if (appLoading.value == null) return false;
  return appLoading.value;
});

const showExamList = computed(() => {
  return step.value === 3 && !isCustom.value && !isReviewQuestions.value;
});
const showAttemptSettings = computed(() => {
  return step.value === 3 && (isCustom.value || isReviewQuestions.value);
});

const isCustom = computed(() => {
  return selectedType.value != null && selectedType.value.includes(ATTEMPT_CUSTOM);
});
const isReviewQuestions = computed(() => {
  return selectedType.value != null && selectedType.value.includes(ATTEMPT_BY_GENERAL_PROCESAL);
});
const availableQuestions = computed(() => {
  if (newAttemptInfoRecord.value != null) {
    return newAttemptInfoRecord.value.questions;
  }
  return 0;
});

const steps = computed(() => 4);
const currentStep = computed(() => {
  return step.value != null ? step.value : steps.value;
});

watch(selectedPublicExamination, async (selectedPublicExamination) => {
  if (selectedPublicExamination != null && selectedPublicExamination.userHasMembership) {
    try {
      await callWs({ slug: selectedPublicExamination.slug });
      await callExamStatistics({ slug: selectedPublicExamination.slug });
    } catch (error) {
      sendServerError(error);
    }
  } else {
    record.value = null;
  }
});

onMounted(async () => {
  await getNotReviewed();
});

async function getNotReviewed() {
  if (user.value === null || !user.value.isStaff) {
    return;
  }

  // use limit 1 because we are only interested in count to know how much they are
  // this avoids to make n+1 queries on the backend
  try {
    let response = await MainRepository.questionList({
      reviewed: 0,
      without_exams: true,
      limit: 1,
    });
    without_review.value.questions = response.data.count;

    response = await MainRepository.examList({
      reviewed: 0,
      exam_type: "previousCall",
      limit: 1,
    });
    without_review.value.tests = response.data.count;

    response = await MainRepository.examList({
      reviewed: 0,
      exam_type: "alleged",
      limit: 1,
    });
    without_review.value.practical = response.data.count;

    response = await MainRepository.examList({
      reviewed: 0,
      exam_type: "allegedPreviousCall",
      limit: 1,
    });
    without_review.value.practicalOfficial = response.data.count;
  } catch (error) {
    console.error(error);
  }
}

async function preNewAttempt() {
  if (selectedExam.value != null) {
    await newAttempt();
  } else {
    await callNewAttemptInfo(buildNewAttemptParams());
    if (newAttemptInfoRecord.value.questions >= nQuestions.value) {
      await newAttempt();
    } else {
      dialogOpen.value = true;
    }
  }
}

async function newAttempt() {
  buttonCreatedDisabled.value = true;
  try {
    await callNewAttempt(buildNewAttemptParams());
    if (newAttemptError.value == null || newAttemptError.value === "") {
      sendNotification(null, "Éxito", "Suerte con su test");
      move(newAttemptRecord.value.uuid);
    } else if (newAttemptError.value === 400) {
      sendNotification("error", "Información", "No existen suficientes preguntas con los parámetros seleccionados");
    } else {
      sendError("Información", "NEW-ATT");
    }
  } catch (e) {
    if (
      e.response &&
      e.response.data &&
      e.response.data.length > 0 &&
      e.response.data[0].code === "attempt_no_questions_matching_criteria"
    ) {
      sendWarnNotification("No se podido crear el intento", e.response.data[0].message);
    } else {
      sendServerError(e, "NEW-ATT");
    }
  }
  buttonCreatedDisabled.value = false;
}

function buildNewAttemptParams() {
  let attemptType = isCustom.value ? ATTEMPT_CUSTOM : selectedType.value;
  // type general procesal is the same as custom but filtering by subtype
  if (attemptType === ATTEMPT_BY_GENERAL_PROCESAL) {
    attemptType = ATTEMPT_CUSTOM;
  }
  const data = {
    publicExamination: selectedPublicExamination.value.id,
    forceNew: true,
    type: attemptType,
  };
  if (!isCustom.value && !isReviewQuestions.value) {
    data["exam"] = selectedExam.value.id;
  } else {
    if (!isReviewQuestions.value && selectedContents.value != null && selectedContents.value.values.length > 0) {
      data[selectedContents.value.type] = selectedContents.value.values.map((a) => a.id);
    }
    if (nQuestions.value != null) {
      data["maxQuestions"] = nQuestions.value;
    }
    if (selectedDifficulty.value != null) {
      data["difficulty"] = selectedDifficulty.value;
    }
    switch (selectedType.value) {
      case ATTEMPT_CUSTOM_BLANK:
        data["questionType"] = "was_blank";
        break;
      case ATTEMPT_CUSTOM_INCORRECT:
        data["questionType"] = "was_incorrect";
        break;
      case ATTEMPT_CUSTOM_FAVOURITES:
        data["questionType"] = "is_starred";
        break;
      case ATTEMPT_CUSTOM_UNCERTAIN:
        data["questionType"] = "is_uncertain";
        break;
    }
    if (isReviewQuestions.value) {
      data["questionSubtype"] = selectedSubtype.value;
    }
  }
  return data;
}

function selectPublicExamination(publicExamination) {
  if (publicExamination.userHasMembership) {
    step.value = 2;
    selectedPublicExamination.value = publicExamination;
  } else {
    // router.push({ name: "public-examination-payment", params: { slug: publicExamination.slug } });
    sendError("No tienes acceso", "Sin suscripción a la oposición.");
  }
}

function changeStep(step) {
  switch (step) {
    case 1:
      unselectPublicExamination();
      break;
    case 2:
      unselectAttemptType();
      break;
    case 3:
      unselectParams();
      break;
  }
}

function unselectPublicExamination() {
  step.value = 1;
  selectedPublicExamination.value = null;
  selectedType.value = null;
  selectedExam.value = null;
  selectedContents.value = null;
}

function selectAttemptType(type) {
  step.value = 3;
  selectedType.value = type;
}

function unselectAttemptType() {
  step.value = 2;
  selectedType.value = null;
  selectedDifficulty.value = null;
  selectedExam.value = null;
  selectedContents.value = null;
}

function unselectExam() {
  step.value = 3;
}

function unselectParams() {
  step.value = 3;
}

function unselectContents() {
  step.value = 4;
}

function selectDifficulty(difficulty) {
  selectedDifficulty.value = difficulty;
}

function selectSubtype(subtype) {
  selectedSubtype.value = subtype;
}

function setContents(contents) {
  selectedContents.value = contents;
  review();
}

function updateNQuestions(value) {
  nQuestions.value = value;
}

function updateExam(value) {
  selectedExam.value = value;
}

function move(uuid) {
  router.push({ name: "in-attempt", params: { uuid: uuid } });
}

function review() {
  step.value = null;
}
</script>

<template>
  <no-header-view>
    <div class="test-new-header relative mb-10">
      <img src="@/assets/test-heading-background.png" class="w-full" aria-hidden="true" />
      <div class="absolute left-0 top-0 flex h-full w-full content-end items-end gap-10 p-10 pr-20">
        <div class="flex-grow">
          <img src="@/assets/logo-ruth-galvan-horizontal-negro.svg" class="test-new-header-logo w-80" alt="" />
        </div>
        <div class="test-new-header-details flex flex-col justify-end">
          <p class="text-xs font-semibold 2xl:text-sm">PREGUNTAS</p>
          <p class="text-sm font-normal 2xl:text-base">+35k preguntas disponibles</p>
        </div>
        <div class="test-new-header-details flex flex-col justify-end">
          <p class="text-xs font-semibold 2xl:text-sm">EXÁMENES</p>
          <p class="text-sm font-normal 2xl:text-base">+50 exámenes oficiales</p>
        </div>
        <div class="test-new-header-details flex flex-col justify-end">
          <p class="text-xs font-semibold 2xl:text-sm">CASOS PRÁCTICOS</p>
          <p class="text-sm font-normal 2xl:text-base">+400 casos prácticos</p>
        </div>
      </div>
    </div>
    <div class="test-steps mb-16 ml-1 mt-10">
      <attempt-selector-steps :steps="steps" :current-step="currentStep" @step-clicked="changeStep" />
    </div>
    <div v-if="isLoading">
      <the-home-dummy />
    </div>
    <div v-else class="flex flex-col">
      <fade-transition>
        <attempt-selector-public-examination
          v-if="step === 1"
          :public-examinations="publicExaminations"
          @select="selectPublicExamination"
        />
        <attempt-selector-type
          v-if="step === 2"
          :public-examination="selectedPublicExamination"
          :exam-statistics="examStatistics"
          @unselect-public-examination="unselectPublicExamination"
          @select-type="selectAttemptType"
        />
        <attempt-selector-settings
          v-if="showAttemptSettings"
          :public-examination="selectedPublicExamination"
          :selected-type="selectedType"
          :selected-difficulty="selectedDifficulty"
          :selected-subtype="selectedSubtype"
          :questions="nQuestions"
          @unselect-public-examination="unselectPublicExamination"
          @unselect-type="unselectAttemptType"
          @select-difficulty="selectDifficulty"
          @select-subtype="selectSubtype"
          @update-questions="updateNQuestions"
          @move="setContents"
        />
        <attempt-selector-exam
          v-if="showExamList"
          :selected-exam="selectedExam"
          :selected-type="selectedType"
          :public-examination="selectedPublicExamination"
          @unselect-public-examination="unselectPublicExamination"
          @unselect-type="unselectAttemptType"
          @update-exam="updateExam"
          @move="review"
        />

        <attempt-selector-review
          v-if="step == null"
          :selected-exam="selectedExam"
          :selected-type="selectedType"
          :public-examination="selectedPublicExamination"
          :selected-difficulty="selectedDifficulty"
          :questions="nQuestions"
          :selected-contents="selectedContents"
          :disabled="buttonCreatedDisabled"
          @unselect-public-examination="unselectPublicExamination"
          @unselect-type="unselectAttemptType"
          @unselect-exam="unselectExam"
          @unselect-params="unselectParams"
          @unselect-contents="unselectContents"
          @create="preNewAttempt"
        />
      </fade-transition>
      <attempt-selector-insuficient-questions-dialog
        :available-questions="availableQuestions"
        :desired-questions="nQuestions"
        :is-open="dialogOpen"
        @close-dialog="dialogOpen = false"
        @accept="newAttempt"
      />
    </div>
  </no-header-view>
</template>
