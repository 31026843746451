<script setup>
import _ from "lodash";

import BaseTr from "@/components/tables/BaseTr.vue";
import BaseTh from "@/components/tables/BaseTh.vue";
import BaseTd from "@/components/tables/BaseTd.vue";

import { computed } from "vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseIcon from "@/components/BaseIcon.vue";

const props = defineProps({
  busy: {
    type: Boolean,
    default: false,
  },
  /**
   * Every object in this array represente a column in the table. The following properties are available:
   *   * key: key to get the element from table row (every row is an object)
   *   * label: the label will show in the header for the column the label represents
   *   * formatter: [optional] a function that receives the value of the row in the table and the whole row
   *   * helpColumn: [optional] this will show a question mark icon in the column of the data. The table will emit
   *   an event with the row and column pressed by the user (as an object you can destructure like {row, column})
   */
  fields: {
    type: Array,
    required: true,
  },
  items: {
    type: Array,
    default: () => [],
  },
  textNoResults: {
    type: String,
    default: "No hay resultados",
  },
  id: {
    type: String,
    default: null,
  },
});

const emit = defineEmits(["help"]);

const fieldsLength = computed(() => {
  return props.fields.length;
});

const itemsLength = computed(() => {
  return props.items.length;
});

function theLabel(field) {
  if (_.has(field, "label")) {
    return field.label;
  }
  return field.key;
}

function theValue(formatter, row, key) {
  try {
    return formatter(row[key], row);
  } catch (e) {
    return "";
  }
}
</script>

<template>
  <div class="w-full overflow-auto rounded-lg">
    <table class="w-full table-auto">
      <thead class="bg-[#F7F8FF]">
        <base-tr>
          <base-th
            v-for="(field, index) in fields"
            :key="index"
            v-show="!field.hide"
            class="!bg-[#F7F8FF] text-base font-medium !text-[#031E33]"
          >
            {{ theLabel(field) }}
          </base-th>
        </base-tr>
      </thead>
      <tbody v-if="busy" class="divide-y divide-secondary-200">
        <base-tr v-if="busy" class="odd:bg-white even:rounded-xl even:bg-background">
          <base-td :colspan="fieldsLength" class="text-center">
            <slot name="table-busy">
              <base-loading :size="6"> Cargando</base-loading>
            </slot>
          </base-td>
        </base-tr>
      </tbody>
      <tbody v-else class="divide-y divide-table-border">
        <base-tr v-if="itemsLength === 0" class="odd:bg-white even:rounded-xl even:bg-background">
          <base-td :colspan="fieldsLength" class="text-center">
            <slot name="table-no-results">
              {{ textNoResults }}
            </slot>
          </base-td>
        </base-tr>
        <base-tr v-for="(row, index) in items" :key="index" class="odd:bg-white even:rounded-xl even:bg-background">
          <base-td v-for="(item, tdIndex) in fields" :key="`tr-` + index + `-td-` + tdIndex" v-show="!item.hide">
            <slot name="cell()" :row="row">
              <slot :name="'cell(' + item.key + ')'" :row="row" :td-index="tdIndex">
                <div v-if="item.hide !== true" class="flex gap-1">
                  <div v-if="item.formatter != null">
                    {{ theValue(item.formatter, row, item.key) }}
                  </div>
                  <div v-else>{{ row[item.key] }}</div>
                  <base-icon
                    v-if="row.helpColumn && item.key === row.helpColumn"
                    title="Ayuda"
                    alt="Ayuda"
                    icon-name="question"
                    class="inline-block h-6 w-6 cursor-pointer"
                    @click="emit('help', { row, column: item })"
                  />
                </div>
              </slot>
            </slot>
          </base-td>
        </base-tr>
      </tbody>
    </table>
  </div>
</template>
