import { computed } from "vue";

/**
 * This component has some utilities to handle public examinations
 * @param publicExaminations
 * @returns {{userPublicExaminations: ComputedRef<[]|*>}}
 */
export function usePublicExaminations(publicExaminations) {
  const userPublicExaminations = computed(() => {
    return getUserPublicExaminations(publicExaminations);
  });

  function getUserPublicExaminations(publicExaminations) {
    if (!publicExaminations) {
      return [];
    }
    return publicExaminations.filter((item) => item.userHasMembership === true);
  }

  return {
    userPublicExaminations,
  };
}
