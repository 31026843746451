<script setup>
import { VueTelInput } from "vue-tel-input";
import { ref, watch } from "vue";
import { watchDebounced } from "@vueuse/core";
import BaseError from "@/components/BaseError.vue";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  error: {
    type: [String, Array],
    default: "",
  },
});

const emit = defineEmits(["update:modelValue", "debouncedInput"]);

const mostRecentValue = ref(props.modelValue);

watch(
  mostRecentValue,
  (newValue) => {
    emit("update:modelValue", newValue);
  },
  { immediate: true }
);

watchDebounced(
  mostRecentValue,
  (newValue) => {
    emit("debouncedInput", newValue);
  },
  { debounce: 500 }
);
</script>

<template>
  <div>
    <vue-tel-input
      class="!border-white bg-background text-secondary-600 invalid:border-red-200"
      v-model="mostRecentValue"
      mode="local"
      :input-options="{ placeholder: 'Introduce tu teléfono' }"
      :only-countries="['ES']"
      default-country="ES"
    />
    <base-error :error="error" />
  </div>
</template>
