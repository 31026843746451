<script setup>
import MainRepository from "@/repositories/MainRepository.js";
import { computed, inject, onMounted, provide, watch } from "vue";
import { useWS } from "@/components/composables/ws-call.js";
import { useToast } from "@/components/composables/notifications.js";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import { useRoute, useRouter } from "vue-router";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";
import * as Sentry from "@sentry/browser";
import BaseCard from "@/components/BaseCard.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import PageTitle from "@/components/PageTitle.vue";
import { usePublicExaminations } from "@/components/composables/publicExaminations.js";

const { sendServerError } = useToast();
const route = useRoute();
const router = useRouter();

const { callWs, record: currentPublicExamination } = useWS(MainRepository.publicExaminationDetail);
provide("public-examination", currentPublicExamination);

onMounted(async () => {
  await getPublicExaminationData(route.params.slug);
});

watch(
  () => route.params.slug,
  (newValue, oldValue) => {
    if (newValue !== oldValue) {
      getPublicExaminationData(newValue);
    }
  }
);

const publicExaminations = inject("publicExaminations");
const { userPublicExaminations } = usePublicExaminations(publicExaminations.value);

const handleOppositionChange = async (publicExamination) => {
  try {
    await router.replace({ name: "public-examination-attempt-history", params: { slug: publicExamination.slug } });
    await getPublicExaminationData(publicExamination.slug);
  } catch (error) {
    sendServerError(error);
  }
};

async function getPublicExaminationData(slug) {
  if (!slug) {
    return;
  }
  try {
    await callWs({ slug: slug });
  } catch (e) {
    sendServerError(e, "PEX-PEND");
  }

  if (!currentPublicExamination.value) {
    Sentry.withScope(function (scope) {
      scope.setTag("wopo-error-slug", route.params.slug.toString());
      Sentry.captureMessage("Public examination summary without record");
    });
  }
  if (!currentPublicExamination.value.userHasMembership) {
    await router.push({ name: "public-examination-payment", params: { slug: currentPublicExamination.value.slug } });
    return;
  }
}
</script>

<template>
  <no-header-view>
    <div class="test-new-header relative mb-10">
      <img
        src="@/assets/test-heading-background.png"
        class="absolute z-[-1] h-full w-full md:relative"
        aria-hidden="true"
      />
      <div
        class="left-0 top-0 flex h-full w-full flex-col content-end items-start gap-4 p-5 md:absolute md:flex-row md:items-end lg:p-10"
      >
        <div class="flex-grow">
          <img
            src="@/assets/logo-ruth-galvan-horizontal-negro.svg"
            class="test-new-header-logo !w-[80%] md:!w-80"
            alt=""
          />
        </div>
        <div class="test-new-header-details-2 flex flex-col">
          <p class="text-xl font-medium lg:text-2xl">Histórico test</p>
          <p class="text-sm font-normal 2xl:text-base">Historial de test realizados con estadísticas</p>
        </div>
      </div>
    </div>

    <base-card class="custom-shadow mx-0 w-fit rounded-3xl border-0 p-2 drop-shadow-none">
      <div class="flex gap-4">
        <base-icon class="p-0" icon-name="basculacv" />
        <div>
          <base-label label="Oposición" for-label="select-opposition" />
          <base-complex-select
            v-model="currentPublicExamination"
            id="select-opposition"
            name="select-opposition"
            :value-option="(item) => item.id"
            :label-option="(item) => item.name"
            :options="userPublicExaminations"
            @update:model-value="handleOppositionChange"
            class="w-fit"
          >
          </base-complex-select>
        </div>
      </div>
    </base-card>

    <router-view v-slot="{ Component }">
      <fade-transition>
        <component :is="Component" />
      </fade-transition>
    </router-view>
  </no-header-view>
</template>
