<script setup>
const props = defineProps({
  modelValue: {
    default: null,
  },
  id: {
    type: String,
    default: "select",
  },
  options: {
    type: Array,
    required: true,
  },
  rowLayout: {
    type: Boolean,
    default: true,
  },
  buttonClass: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["update:modelValue"]);
</script>

<template>
  <div :id="id" :class="['flex', rowLayout ? 'flex-row' : 'flex-col']">
    <button
      v-for="(option, index) in options"
      :key="index"
      :name="id + '-' + index"
      :class="[
        modelValue !== null && option.value === modelValue
          ? 'bg-secondary-800 text-white hover:bg-opacity-60'
          : 'bg-secondary-150 text-secondary-800 hover:bg-opacity-60',
        'mr-2 justify-center rounded-lg px-10 py-3 text-base font-medium focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75',
        buttonClass,
      ]"
      @click.prevent="emit('update:modelValue', option.value)"
    >
      {{ option.label }}
    </button>
  </div>
</template>
