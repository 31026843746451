<script setup>
import { computed, inject, onMounted, ref, watch } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import MainRepository from "@/repositories/MainRepository.js";
import BaseCard from "@/components/BaseCard.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import ResultCanvas from "@/components/attempt-details/ResultCanvas.vue";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import RadioButton from "@/components/forms/RadioButton.vue";
import PublicExaminationStatisticsChart from "@/components/charts/PublicExaminationStatisticsChart.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import { useRoute, useRouter } from "vue-router";
import StatisticsSelectorFailedThemeList from "@/components/statistics/StatisticsSelectorFailedThemeList.vue";
import { DateTime } from "luxon";
import { formatDate } from "@/components/utils.js";
import StatisticsSelectorFailedLawList from "@/components/statistics/StatisticsSelectorFailedLawList.vue";
import UserInputSelector from "@/components/forms/UserInputSelector.vue";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";
import PageTitle from "@/components/PageTitle.vue";
import PublicExaminationSelect from "@/components/statistics/PublicExaminationSelect.vue";
import { usePublicExaminations } from "@/components/composables/publicExaminations.js";
import ButtonPrimaryWithArrow from "@/components/buttons/ButtonPrimaryWithArrow.vue";

const { sendServerError } = useToast();

const router = useRouter();
const route = useRoute();

const oppositions = inject("publicExaminations");
const opposition = ref(null);
const oppositionStatistics = ref(null);
const correctAnswerList = ref([]);
const incorrectAnswerList = ref([]);
const blankAnswerList = ref([]);
const statisticsTime = ref("7");

const endDate = computed(() => {
  const today = DateTime.now();
  return formatDate(today);
});

const startDate = computed(() => {
  let firstDate = DateTime.now();

  if (statisticsTime.value === "7") {
    firstDate = firstDate.minus({ days: 7 });
  } else if (statisticsTime.value === "30") {
    firstDate = firstDate.minus({ days: 30 });
  } else if (statisticsTime.value === "365") {
    firstDate = firstDate.minus({ days: 365 });
  }

  return formatDate(firstDate);
});

const numberOfCorrect = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.numberOfCorrectQuestion;
});
const percentageOfCorrect = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.percentageCorrectQuestion;
});
const numberOfIncorrect = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.numberOfIncorrectQuestion;
});
const percentageOfIncorrect = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.percentageIncorrectQuestion;
});
const numberOfBlank = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.numberOfBlankQuestion;
});
const percentageOfBlank = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.percentageBlankQuestion;
});
const totalAttempts = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.totalAttempts;
});
const totalTime = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.totalTimeStringRepresentation;
});

const publicExaminationId = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return opposition.value.id;
});

const calculatePercentageCorrectDifference = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }

  const lastPercentageCorrect = oppositionStatistics.value.lastPercentageCorrectQuestion;
  const currentPercentageCorrect = oppositionStatistics.value.percentageCorrectQuestion;
  const diffCorrect = (currentPercentageCorrect - lastPercentageCorrect) * 100;

  if (diffCorrect >= 0) {
    return `Has aumentado un ${Math.round(Math.abs(diffCorrect))}% tus aciertos.`;
  } else {
    return `Has disminuido un ${Math.round(Math.abs(diffCorrect))}% tus aciertos.`;
  }
});

const calculatePercentageIncorrectDifference = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }

  const lastPercentageIncorrect = oppositionStatistics.value.lastPercentageIncorrectQuestion;
  const currentPercentageIncorrect = oppositionStatistics.value.percentageIncorrectQuestion;
  const diffIncorrect = (currentPercentageIncorrect - lastPercentageIncorrect) * 100;

  if (diffIncorrect >= 0) {
    return `Has aumentado un ${Math.round(Math.abs(diffIncorrect))}% tus fallos.`;
  } else {
    return `Has disminuido un ${Math.round(Math.abs(diffIncorrect))}% tus fallos.`;
  }
});

const calculatePercentageBlankDifference = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }

  const lastPercentageBlank = oppositionStatistics.value.lastPercentageBlankQuestion;
  const currentPercentageBlank = oppositionStatistics.value.percentageBlankQuestion;
  const diffBlank = (currentPercentageBlank - lastPercentageBlank) * 100;

  if (diffBlank >= 0) {
    return `Has aumentado un ${Math.round(Math.abs(diffBlank))}% tus respuestas en blanco.`;
  } else {
    return `Has disminuido un ${Math.round(Math.abs(diffBlank))}% tus respuestas en blanco.`;
  }
});

const { userPublicExaminations } = usePublicExaminations(oppositions.value);

const handleOppositionChange = async (selectedOpposition) => {
  try {
    await router.replace({ name: "public-examination-user-statistics", params: { slug: selectedOpposition.slug } });
  } catch (error) {
    sendServerError(error);
  }
};

const statisticsTimeOptions = [
  { label: "Últimos 365 días", value: "365" },
  { label: "Últimos 30 días", value: "30" },
  { label: "Últimos 7 días", value: "7" },
];

const isPercentage = ref(true);
const resultSelector = computed(() => [
  {
    label: "Porcentaje",
    value: true,
  },
  {
    label: "Valor absoluto",
    value: false,
  },
]);

const calculatePercentages = () => {
  if (!isPercentage.value) {
    return;
  }

  const length = correctAnswerList.value.length;
  for (let i = 0; i < length; i++) {
    const correct = correctAnswerList.value[i].value;
    const incorrect = incorrectAnswerList.value[i].value;
    const blank = blankAnswerList.value[i].value;

    const total = correct + incorrect + blank;

    if (total === 0) {
      correctAnswerList.value[i].value = 0;
      incorrectAnswerList.value[i].value = 0;
      blankAnswerList.value[i].value = 0;
    } else {
      correctAnswerList.value[i].value = Math.round((correct / total) * 100);
      incorrectAnswerList.value[i].value = Math.round((incorrect / total) * 100);
      blankAnswerList.value[i].value = Math.round((blank / total) * 100);
    }
  }
};

function moveToThemes() {
  const slug = route.params.slug;
  router.push({
    name: "public-examination-themes-statistics",
    params: { slug },
    query: { statisticsTime: statisticsTime.value },
  });
}

function moveToLaws() {
  const slug = route.params.slug;
  router.push({
    name: "public-examination-laws-statistics",
    params: { slug },
    query: { statisticsTime: statisticsTime.value },
  });
}

watch(isPercentage, () => {
  fetchOppositionStatistics();
});

onMounted(() => fetchData(route.params.slug));

watch(
  () => route.params.slug,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await fetchData(newValue);
    }
  }
);

const user = inject("user");
const userIdForStatistics = ref(null);

const changeUser = (newUserId) => {
  userIdForStatistics.value = newUserId;
  fetchOppositionStatistics();
};

const fetchData = async (slug) => {
  try {
    if (slug) {
      opposition.value = userPublicExaminations.value.find((item) => item.slug === slug);
    }
    if (!opposition.value && userPublicExaminations.value.length > 0) {
      opposition.value = oppositions.value[0];
    }
    await fetchOppositionStatistics();
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
};

const fetchOppositionStatistics = async () => {
  const slug = route.params.slug;
  console.log(slug);
  if (!slug) {
    return;
  }

  try {
    const response = await MainRepository.publicExaminationStatistics({
      slug,
      timeRange: statisticsTime.value,
      userId: userIdForStatistics.value,
    });
    oppositionStatistics.value = response.data;
    console.log(response.data);
    correctAnswerList.value = response.data.correctAnswerList;
    incorrectAnswerList.value = response.data.incorrectAnswerList;
    blankAnswerList.value = response.data.blankAnswerList;
    calculatePercentages();
  } catch (error) {
    sendServerError(error, "FETCH-OPPOSITION-STATISTICS");
  }
};

const mostFailedContentLevels = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  const filteredList = oppositionStatistics.value.topFailThemeList.filter((item) => item.incorrectPercentage !== 0);
  return filteredList.slice(0, 5);
});
const hasMostFailedContentLevels = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return mostFailedContentLevels.value.length > 0;
});

const mostFailedLaw = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  const filteredList = oppositionStatistics.value.topFailLawList.filter((item) => item.incorrectPercentage !== 0);
  return filteredList.slice(0, 5);
});
const hasMostFailedLaws = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return mostFailedLaw.value.length > 0;
});
</script>

<template>
  <no-header-view>
    <div>
      <div class="test-new-header relative mb-10">
        <img
          src="@/assets/test-heading-background.png"
          class="absolute z-[-1] h-full w-full md:relative"
          aria-hidden="true"
        />
        <div
          class="left-0 top-0 flex h-full w-full flex-col content-end items-start gap-4 p-5 md:absolute md:flex-row md:items-end lg:p-10"
        >
          <div class="flex-grow">
            <img
              src="@/assets/logo-ruth-galvan-horizontal-negro.svg"
              class="test-new-header-logo !w-[80%] md:!w-80"
              alt=""
            />
          </div>
          <div class="test-new-header-details-2 flex flex-col">
            <p class="text-xl font-medium lg:text-2xl">Estadísticas generales</p>
            <p class="text-sm font-normal 2xl:text-base">Estadísticas generales por oposición</p>
          </div>
        </div>
      </div>
      <base-card class="custom-shadow mx-0 w-fit rounded-3xl border-0 p-2 drop-shadow-none">
        <div class="flex gap-4">
          <base-icon class="p-0" icon-name="basculacv" />
          <div>
            <public-examination-select
              v-model="opposition"
              :public-examinations="oppositions"
              @update:model-value="handleOppositionChange"
              class="!p-0 !shadow-none"
            />
          </div>
        </div>
      </base-card>

      <base-card
        v-if="user !== null && user.isSuperuser"
        class="custom-shadow my-0 w-full rounded-3xl border-0 bg-admin p-8 drop-shadow-none"
      >
        <div class="w-full">
          <base-label label="Cambiar usuario" for-label="user-id" />
          <user-input-selector id="user-id" @user-id="changeUser" />
        </div>
      </base-card>

      <base-card class="custom-shadow-only-desktop !mx-0 mt-8 w-full rounded-3xl border-0 p-0 drop-shadow-none md:p-8">
        <div class="flex flex-col xl:flex-row xl:gap-4">
          <div class="mb-5 mt-3 flex flex-col">
            <p v-if="opposition" class="text-3xl font-medium text-secondary-800">{{ opposition.shortName }}</p>
          </div>
          <div
            class="flex flex-grow flex-wrap items-start justify-between gap-2 xl:flex-nowrap xl:items-center xl:justify-end xl:gap-2"
          >
            <p class="order-3 ml-auto w-full text-base font-normal text-[#031E33] xl:order-2 xl:w-auto">
              Del {{ startDate }} al {{ endDate }}
            </p>
            <div class="order-1 xl:order-3">
              <base-select
                v-model="statisticsTime"
                id="select-statistics-time"
                name="select-statistics-time"
                :options="statisticsTimeOptions"
                @change="fetchOppositionStatistics"
              />
            </div>
          </div>
        </div>
        <fade-transition>
          <div class="mb-5 grid grid-cols-1 gap-4 sm:grid-cols-2 xl:grid-cols-3">
            <div class="flex w-full items-center rounded-lg bg-[#F7F8FF] p-4">
              <result-canvas
                :value="numberOfCorrect"
                :percentage="percentageOfCorrect"
                percentage-color="#52E684"
                class="rounded-full"
              />
              <div class="ml-4 flex flex-col">
                <div class="text-lg font-medium">Correctas</div>
                <div class="text-base font-light">{{ numberOfCorrect }} respuestas</div>
                <div class="text-sm font-light">{{ calculatePercentageCorrectDifference }}</div>
              </div>
            </div>
            <div class="flex w-full items-center rounded-lg bg-[#F7F8FF] p-4">
              <result-canvas
                :value="numberOfIncorrect"
                :percentage="percentageOfIncorrect"
                percentage-color="#FF5869"
                class="rounded-full"
              />
              <div class="ml-4 flex flex-col">
                <div class="text-lg font-medium">Incorrectas</div>
                <div class="text-base font-light">{{ numberOfIncorrect }} respuestas</div>
                <div class="text-sm font-light">{{ calculatePercentageIncorrectDifference }}</div>
              </div>
            </div>
            <div class="flex w-full items-center rounded-lg bg-[#F7F8FF] p-4">
              <result-canvas
                :value="numberOfBlank"
                :percentage="percentageOfBlank"
                percentage-color="#7492FF"
                class="rounded-full"
              />
              <div class="ml-4 flex flex-col">
                <div class="text-lg font-medium">En blanco</div>
                <div class="text-base font-light">{{ numberOfBlank }} respuestas</div>
                <div class="text-sm font-light">{{ calculatePercentageBlankDifference }}</div>
              </div>
            </div>
          </div>
        </fade-transition>
        <div class="mb-5 mt-10 flex flex-col justify-between gap-4 lg:flex-row lg:gap-0">
          <div class="flex items-center text-center">
            <radio-button
              v-model="isPercentage"
              :options="resultSelector"
              id="radio-law-content-level"
              class="por-ley-o-tema-radio por-ley-o-tema-radio-2"
            />
          </div>
          <div class="flex gap-4">
            <div class="flex items-center gap-3 rounded-lg bg-[#EBE6F2] p-2.5 pr-4">
              <base-icon icon-name="tnumber" class="w-14"></base-icon>
              <div class="flex flex-col">
                <p class="text-base font-semibold text-secondary-800">{{ totalAttempts }}</p>
                <p class="text-sm font-normal text-secondary-800">Test realizados</p>
              </div>
            </div>
            <div class="flex items-center gap-3 rounded-lg bg-[#EBE6F2] p-2.5 pr-4">
              <base-icon icon-name="ttimer" class="w-14"></base-icon>
              <div class="flex flex-col">
                <p class="text-base font-semibold text-secondary-800">{{ totalTime }}</p>
                <p class="text-sm font-normal text-secondary-800">Tiempo total</p>
              </div>
            </div>
          </div>
        </div>
        <public-examination-statistics-chart
          :blank-answers="blankAnswerList"
          :incorrect-answers="incorrectAnswerList"
          :correct-answers="correctAnswerList"
          :is-percentage="isPercentage"
          :show-day-in-date-label="statisticsTime !== '365'"
        >
        </public-examination-statistics-chart>
      </base-card>
      <base-card class="custom-shadow !mx-0 mt-8 w-full rounded-3xl border-0 p-8 drop-shadow-none">
        <div class="flex">
          <div v-if="opposition" class="mb-5 mt-3 flex flex-col">
            <p class="text-3xl font-medium text-secondary-800">{{ opposition.shortName }}</p>
          </div>
        </div>
        <div>
          <div class="mb-5 mt-3 flex gap-4">
            <base-icon icon-name="tfalladas" class="w-14"></base-icon>
            <div>
              <p class="text-xl font-medium text-secondary-800">
                Preguntas falladas <span class="font-bold">por tema</span>
              </p>
              <p class="text-md font-light text-secondary-800">
                Resultados de los test personalizados por ley, tema o parte general/procesal
              </p>
            </div>
          </div>
          <div class="flex flex-row">
            <p v-if="!hasMostFailedContentLevels" class="text-md p-16 text-center font-light text-secondary-800">
              No se encontraron test personalizados con preguntas falladas.
            </p>
            <div v-else class="flex flex-grow flex-col gap-4">
              <statistics-selector-failed-theme-list
                :content-levels="mostFailedContentLevels"
                :public-examination-id="publicExaminationId"
              />
            </div>
          </div>
          <div class="mt-5 flex">
            <button-primary-with-arrow @click.prevent="moveToThemes" class="!text-base">
              Ver todos los temas
            </button-primary-with-arrow>
          </div>
        </div>
      </base-card>
      <base-card class="custom-shadow !mx-0 mt-8 w-full rounded-3xl border-0 p-8 drop-shadow-none">
        <div class="flex">
          <div v-if="opposition" class="mb-5 mt-3 flex flex-col">
            <p class="text-3xl font-medium text-secondary-800">{{ opposition.shortName }}</p>
          </div>
        </div>
        <div>
          <div class="mb-5 mt-3 flex gap-4">
            <base-icon icon-name="tfalladas" class="w-14"></base-icon>
            <div>
              <p class="text-xl font-medium text-secondary-800">
                Preguntas falladas <span class="font-bold">por ley</span>
              </p>
              <p class="text-md font-light text-secondary-800">
                Resultados de los test personalizados por ley, tema o parte general/procesal
              </p>
            </div>
          </div>
          <div class="flex flex-row">
            <p v-if="!hasMostFailedLaws" class="text-md p-16 text-center font-light text-secondary-800">
              No se encontraron test personalizados con preguntas falladas.
            </p>
            <div v-else class="flex flex-grow flex-col gap-4">
              <statistics-selector-failed-law-list :laws="mostFailedLaw" />
            </div>
          </div>
          <div class="mt-5 flex">
            <button-primary-with-arrow @click.prevent="moveToLaws" class="!text-base">
              Ver todas las leyes
            </button-primary-with-arrow>
          </div>
        </div>
      </base-card>
    </div>
  </no-header-view>
</template>
