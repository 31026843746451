<script setup>
import { computed, onBeforeUnmount, onMounted, ref } from "vue";
import { useWS } from "@/components/composables/ws-call.js";
import MainRepository from "@/repositories/MainRepository.js";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import QuestionAttempt from "@/components/attempt-details/QuestionAttempt.vue";
import InAttemptHeader from "@/components/attempt-details/InAttemptHeader.vue";
import InAttemptBottomMenu from "@/components/attempt-details/InAttemptBottomMenu.vue";
import InAttemptDialog from "@/components/attempt-details/InAttemptDialog.vue";
import { useToast } from "@/components/composables/notifications.js";
import FullHeaderView from "@/layout-components/FullHeaderView.vue";
import InAttemptDummy from "@/components/attempt-details/InAttemptDummy.vue";
import BaseCard from "@/components/BaseCard.vue";
import { useMagicKeys, whenever } from "@vueuse/core";
import ScrollToTop from "@/components/ScrollToTop.vue";

const route = useRoute();
const router = useRouter();
const { sendServerError, sendNotification, sendSuccessNotification } = useToast();
const { callWs, record, error } = useWS(MainRepository.attemptDetail(route.params.uuid));
const { callWs: update } = useWS(MainRepository.updateAttempt(route.params.uuid));

const timer = ref(null);
const updateTick = ref(0);
const isOpen = ref(false);
const changesPending = ref(true);
const onlyUncertain = ref(false);
const onlyHighlighted = ref(false);
const onlyNotAnswered = ref(false);

const visibleQuestions = computed(() => {
  return (
    record.value.questions.filter((obj) => {
      return !(
        (onlyNotAnswered.value && !obj.isHighlighted) ||
        (onlyUncertain.value && !obj.isUncertain) ||
        (onlyHighlighted.value && !obj.isHighlighted)
      );
    }).length > 0
  );
});

onMounted(async () => {
  try {
    await callWs({});
    if (record.value && (record.value.status === "finished" || record.value.status === "canceled")) {
      changesPending.value = false;
      await router.push({ name: "attempt-results", params: { uuid: route.params.uuid } });
      return;
    }
    if (record.value.elapsedTime == null) {
      record.value.elapsedTime = 0;
    }
    addTimer();
    //TODO uncomment when ready
    // window.onbeforeunload = function (event) {
    //   event.preventDefault();
    //   const confirmationMessage = "Hola que ase";
    //   event.returnValue = confirmationMessage;
    //   return confirmationMessage;
    // };
  } catch (e) {
    if (e.response && e.response.status === 404) {
      changesPending.value = false;
      await router.push({ name: "not-found" });
    } else {
      sendServerError(e, "ATT-DET");
    }
  }
});

onBeforeUnmount(async () => {
  removeTimer();
});

onBeforeRouteLeave(() => {
  if (changesPending.value) {
    const answer = window.confirm("Desea salir? Los cambios serán descartados!");
    // cancel the navigation and stay on the same page
    if (!answer) return false;
  }
});

async function onTime() {
  record.value.elapsedTime += 1;
  updateTick.value += 1;
  if (updateTick.value === 10) {
    updateTick.value = 0;
    try {
      await updateAttempt();
    } catch (e) {
      sendServerError(e, "ATT-UPD");
      removeTimer();
    }
  }
}

function questionChanged(question, event) {
  console.log(event);
  console.log(question.selectedAnswer);
  if (question.selectedAnswer === event) {
    question.selectedAnswer = null;
  } else {
    question.selectedAnswer = event;
  }
}

function questionStarred(question) {
  question.isStarred = !question.isStarred;
}

function questionUncertain(question) {
  question.isUncertain = !question.isUncertain;
}

function questionHighlight(question) {
  question.isHighlighted = !question.isHighlighted;
}

function changeUncertain(value) {
  onlyUncertain.value = value;
}

function changeHighlighted(value) {
  onlyHighlighted.value = value;
}

function changeNotAnswered(value) {
  onlyNotAnswered.value = value;
}

function buildUpdateData(discard) {
  const output = [];
  if (!discard) {
    for (const question of record.value.questions) {
      output.push({
        id: question.id,
        selectedAnswer: question.selectedAnswer,
        isHighlighted: question.isHighlighted,
        isUncertain: question.isUncertain,
        isStarred: question.isStarred,
      });
    }
  }
  return output;
}

async function updateAttempt(close = false, discard = false) {
  await update({
    questions: buildUpdateData(discard),
    elapsedTime: record.value.elapsedTime,
    discard: discard,
    close: close,
  });
}

function addTimer() {
  timer.value = setInterval(() => {
    onTime();
  }, 1000);
}

function openDialog() {
  isOpen.value = true;
  removeTimer();
}

function closeDialog() {
  isOpen.value = false;
  addTimer();
}

async function save() {
  try {
    await updateAttempt();
    sendSuccessNotification("Éxito", "Examen guardado correctamente");
  } catch (e) {
    sendServerError(e, "ATT-UPD");
    removeTimer();
  }
}

async function saveAndExit() {
  removeTimer();
  try {
    await updateAttempt(true, false);
    sendNotification(null, "Intento finalizado", "Calculando los resultados");
    changesPending.value = false;
    router.push({ name: "attempt-results", params: { uuid: route.params.uuid } });
  } catch (e) {
    sendServerError(e, "ATT-UPD");
  }
}

async function discard() {
  removeTimer();
  try {
    await updateAttempt(true, true);
    sendNotification(null, "Intento descartado", "Su intento ha sido descartado correctamente");
    changesPending.value = false;
    router.push({ name: "home" });
  } catch (e) {
    sendServerError(e, "ATT-UPD");
  }
}

function removeTimer() {
  clearInterval(timer.value);
}

// development only for now, take test with keyboard shortcuts
const currentQuestion = ref(0);
const enableFocusOnQuestion = ref(false);
if (process.env.NODE_ENV !== "production") {
  enableFocusOnQuestion.value = true;
  const { a, s, d, f, j, k } = useMagicKeys();
  whenever(a, (v) => {
    selectAnswerFromShortcuts(currentQuestion.value, 0);
  });
  whenever(s, (v) => {
    selectAnswerFromShortcuts(currentQuestion.value, 1);
  });
  whenever(d, (v) => {
    selectAnswerFromShortcuts(currentQuestion.value, 2);
  });
  whenever(f, (v) => {
    selectAnswerFromShortcuts(currentQuestion.value, 3);
  });
  whenever(j, (v) => {
    currentQuestion.value = Math.min(currentQuestion.value + 1, record.value.questions.length - 1);
    document.querySelector(`#question-index-${currentQuestion.value + 1}`).scrollIntoView({ behavior: "smooth" });
  });
  whenever(k, (v) => {
    currentQuestion.value = Math.max(currentQuestion.value - 1, 0);
    document.querySelector(`#question-index-${currentQuestion.value + 1}`).scrollIntoView({ behavior: "smooth" });
  });
}

function selectAnswerFromShortcuts(index, answer) {
  let selected = record.value.questions[index].answers[answer].id;
  let current = record.value.questions[index].selectedAnswer;
  if (current === selected) {
    record.value.questions[index].selectedAnswer = null;
  } else {
    record.value.questions[index].selectedAnswer = selected;
  }
}
</script>

<template>
  <full-header-view header-classes="fixed bg-white shadow top-[158px] md:top-[167px] w-full left-0 z-[25] lg:top-0">
    <template v-slot:header>
      <in-attempt-header
        :record="record"
        :only-uncertain="onlyUncertain"
        :only-highlighted="onlyHighlighted"
        :only-not-answered="onlyNotAnswered"
        @pause="openDialog"
        @save="save"
        @save-and-exit="saveAndExit"
        @discard="discard"
        @uncertain="changeUncertain"
        @highlighted="changeHighlighted"
        @not-answered="changeNotAnswered"
      />
    </template>
    <div v-if="record == null">
      <in-attempt-dummy />
    </div>
    <div v-else>
      <in-attempt-dialog :is-open="isOpen" @close-dialog="closeDialog">
        <template v-slot:title>Intento pausado</template>
        <template v-slot:buttonText>Reanudar</template>
        <div class="mt-2">
          <p class="text-sm text-gray-500">Modo pausa activado.</p>
        </div>
      </in-attempt-dialog>
      <div class="my-10 text-lg font-light text-secondary-800">
        <base-card v-if="record.examStatement != null && record.examStatement !== ''" class="p-8">
          <div v-html="record.examStatement"></div>
        </base-card>
      </div>
      <div>
        <question-attempt
          v-for="(question, index) in record.questions"
          :key="index"
          :question="question"
          :index="index + 1"
          :id="'id-question-' + question.id"
          :name="'question-' + question.id"
          :show-if-highlighted="onlyHighlighted"
          :show-if-uncertain="onlyUncertain"
          :show-if-not-answered="onlyNotAnswered"
          @input="questionChanged(question, $event)"
          @starred="questionStarred(question)"
          @uncertain="questionUncertain(question)"
          @highlight="questionHighlight(question)"
          :extra-card-class="enableFocusOnQuestion && currentQuestion === index ? 'outline outline-primary-400' : ''"
        />
        <base-card v-show="!visibleQuestions"> No hay preguntas que cumplan los criterios seleccionados</base-card>
      </div>
    </div>
    <template v-slot:footer>
      <scroll-to-top class="bottom-24" />
      <in-attempt-bottom-menu :record="record" @save-and-exit="saveAndExit" />
    </template>
  </full-header-view>
</template>
