<script setup>
const props = defineProps({
  modelValue: {
    default: null,
  },
  id: {
    type: String,
    default: "select",
  },
  name: {
    type: String,
    default: "select",
  },
  options: {
    type: Array,
    required: true,
  },
  showBlankOption: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
</script>

<template>
  <select
    :id="id"
    :name="name"
    :required="required"
    :disabled="disabled"
    class="block h-6 w-full bg-white py-0 text-base text-secondary-600"
    @change="emit('update:modelValue', $event.target.value)"
  >
    <option v-if="showBlankOption" value="">----</option>
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="option.value"
      :selected="modelValue !== null && option.value === modelValue"
    >
      {{ option.label }}
    </option>
  </select>
</template>
