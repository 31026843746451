<script setup>
defineProps({
  modelValue: {
    type: [Number, String],
    default: null,
  },
  id: {
    type: String,
    default: "id-range",
  },
  name: {
    type: String,
    default: "range",
  },
  min: {
    type: [Number, String],
    default: 0,
  },
  max: {
    type: [Number, String],
    default: 10,
  },
  step: {
    type: [Number, String],
    default: 1,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);
</script>
<template>
  <input
    :value="modelValue"
    @input="emit('update:modelValue', $event.target.value)"
    type="range"
    :id="id"
    :name="name"
    :min="min"
    :max="max"
    :step="step"
    :disabled="disabled"
    class="h-1 cursor-pointer appearance-none rounded-full bg-secondary-190 p-0 text-primary-800 accent-primary-800"
  />
</template>

<style scoped>
/***** Chrome, Safari, Opera and Edge Chromium styles *****/
/* slider thumb */
input[type="range"]::-webkit-slider-thumb {
  background-color: #f39200;
  height: 15px;
  width: 15px;
  border-radius: 15px;
  -webkit-appearance: none;
}

/******** Firefox styles ********/

/* slider thumb */
input[type="range"]::-moz-range-thumb {
  background-color: #f39200;
  border-color: #f39200;
  height: 15px;
  width: 15px;
  border-radius: 15px;
}
</style>
