<script setup>
import { useWS } from "@/components/composables/ws-call.js";
import StaffRepository from "@/repositories/StaffRepository.js";
import BaseCard from "@/components/BaseCard.vue";
import { inject, onMounted, ref } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import { cloneDeep } from "lodash";
import RadioButton from "@/components/forms/RadioButton.vue";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import { usePagination } from "@/components/composables/ws-pagination.js";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import { useRouter } from "vue-router";
import PhoneInput from "@/components/forms/PhoneInput.vue";

const props = defineProps({
  wopoUserId: {
    type: [Number, String],
    default: null,
  },
});

const { sendError, sendServerError, sendWarnNotification, sendNotification } = useToast();
const router = useRouter();

const user = inject("user");
const toUpdate = ref(null);

const { callWs, record } = useWS(StaffRepository.userDetail());
const {
  callWs: createUser,
  record: createUserRecord,
  error: createUserResponse,
  erroMsg: errorCreateUserMsg,
} = useWS(StaffRepository.createUser());

const {
  callWs: updateUserProfile,
  error: updateResponse,
  erroMsg: errorProfileMsg,
} = useWS(StaffRepository.updateUserProfile(props.wopoUserId));
const { callWs: updateUserAdminProps, error: updateAdminPropsResponse } = useWS(
  StaffRepository.updateUserAdminProps(props.wopoUserId)
);
const { items: academies, getList } = usePagination(StaffRepository.academyList, 100, 0);

const isStaffOptions = [
  { label: "Sí", value: true },
  { label: "No", value: false },
];
const isActiveOptions = [
  { label: "Activo", value: true },
  { label: "Inactivo", value: false },
];
const roleOptions = [
  { label: "Alumno", value: "user" },
  { label: "Staff de academia", value: "academy" },
];

onMounted(async () => {
  if (props.wopoUserId != null) {
    try {
      await callWs(props.wopoUserId);
      toUpdate.value = { academy: null, ...cloneDeep(record.value.user) };
      if (record.value.academy != null) {
        toUpdate.value.academy = record.value.academy;
      }
    } catch (e) {
      sendServerError(e, "IN-INIT");
    }
  } else {
    toUpdate.value = {
      firstName: null,
      lastName: null,
      email: null,
      phone: "",
      isActive: true,
      isStaff: false,
      username: null,
      academy: null,
      role: "user",
    };
  }

  try {
    await getList();
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
});

async function updateProfile() {
  try {
    const params = { ...toUpdate.value };
    if (params.academy != null) {
      params.academy = params.academy.id;
    }
    let message;
    let code;
    if (props.wopoUserId != null) {
      await updateUserProfile(params);
      message = errorProfileMsg.value;
      code = updateResponse.value;
    } else {
      await createUser(params);
      message = errorCreateUserMsg.value;
      code = createUserResponse.value;
    }

    if (code === 400) {
      if ("username" in message) {
        sendWarnNotification("Datos erróneos", errorProfileMsg.value.username);
        toUpdate.value.username = null;
      }
      if ("email" in message) {
        sendWarnNotification("Datos erróneos", errorProfileMsg.value.email);
        toUpdate.value.email = null;
      }
    } else {
      if (props.wopoUserId != null) {
        sendNotification("success", "Éxito", "Datos de usuario guardados correctamente");
      } else {
        sendNotification("success", "Éxito", "Usuario creado correctamente");
        router.push({ name: "edit-user", params: { id: createUserRecord.value.wopocampususer } });
      }
    }
  } catch (e) {
    if (e.response && e.response.data) {
      let errors = "";
      if (e.response.data.username) {
        errors += e.response.data.username.join(",");
        errors += "\n";
      }
      if (e.response.data.email) {
        errors += e.response.data.email.join(",");
        errors += "\n";
      }
      sendError(errors);
    } else {
      sendServerError(e, "IN-INIT");
    }
  }
}

async function updateAdminProps() {
  try {
    await updateUserAdminProps({ isStaff: toUpdate.value.isStaff, isActive: toUpdate.value.isActive });
    if (updateAdminPropsResponse.value === 403) {
      sendWarnNotification("Denegado", "No tiene permisos");
    } else {
      sendNotification("success", "Éxito", "Datos de usuario guardados correctamente");
    }
  } catch (e) {
    sendServerError(e, "IN-INIT");
  }
}
</script>
<template>
  <div>
    <base-card>
      <form @submit.prevent="updateProfile">
        <div class="mx-2 my-4 flex flex-col">
          <h2 class="text-2xl font-semibold text-secondary-800">Perfil de usuario</h2>
          <div class="sm:gid-cols-1 mt-4 grid gap-4 md:grid-cols-2" v-if="toUpdate != null">
            <div class="">
              <base-label label="Nombre*" for-label="input-first-name" />
              <base-input
                class="w-[40rem] border-secondary-200 bg-background text-secondary-600 invalid:border-red-200"
                v-model="toUpdate.firstName"
                id="input-first-name"
                name="input-first-name"
                :required="true"
              />
            </div>
            <div class="">
              <base-label label="Apellidos*" for-label="input-last-name" />
              <base-input
                class="w-[40rem] border-secondary-200 bg-background text-secondary-600 invalid:border-red-200"
                v-model="toUpdate.lastName"
                id="input-last-name"
                name="input-last-name"
                :required="true"
              />
            </div>
            <div class="">
              <base-label label="Email*" for-label="input-email" />
              <base-input
                class="w-[40rem] border-secondary-200 bg-background text-secondary-600 invalid:border-red-200"
                v-model="toUpdate.email"
                id="input-email"
                name="input-email"
                :required="true"
              />
            </div>
            <div class="">
              <base-label label="Teléfono" for-label="input-phone" />
              <phone-input v-model="toUpdate.phone"></phone-input>
            </div>
            <div>
              <base-label label="Nombre de usuario*" for-label="input-username" />
              <base-input
                class="w-[40rem] border-secondary-200 bg-background text-secondary-600 invalid:border-red-200"
                v-model="toUpdate.username"
                id="input-username"
                name="input-username"
                :required="true"
              />
            </div>
          </div>
          <h2 class="mt-8 text-2xl font-semibold text-secondary-800">Academia</h2>
          <div class="sm:gid-cols-1 mt-4 grid gap-4 md:grid-cols-2" v-if="toUpdate != null">
            <div class="" v-if="user.isStaff || user.isSuperuser">
              <base-label label="Academia" for-label="select-academy" />
              <base-complex-select
                v-model="toUpdate.academy"
                id="select-academy"
                name="select-academy"
                :value-option="(item) => item.id"
                :label-option="(item) => item.name"
                :options="academies"
                show-blank-option
              >
                <template v-slot:blankOption> Sin academia</template>
              </base-complex-select>
            </div>
            <div v-else>
              <base-label label="academia" for-label="input-academy" />
              <div class="mt-3">
                <span v-if="record.academy">{{ record.academy }}</span>
                <span v-else>Sin asignar</span>
              </div>
            </div>
            <div class="">
              <base-label label="Rol de usuario" for-label="input-is-staff" />
              <radio-button v-model="toUpdate.role" :options="roleOptions" id="input-role" button-class="!py-2" />
            </div>
          </div>
          <div class="mt-4 text-center">
            <button-secondary><span v-if="wopoUserId != null">Guardar</span><span v-else>Crear</span></button-secondary>
          </div>
        </div>
      </form>
    </base-card>
    <base-card v-if="(user.isStaff || user.isSuperuser) && wopoUserId != null" class="mt-8">
      <form @submit.prevent="updateAdminProps">
        <div class="mx-2 my-4 flex flex-col">
          <h2 class="text-2xl font-semibold text-secondary-800">Permisos de usuario</h2>
          <div class="sm:gid-cols-1 mt-4 grid gap-4 md:grid-cols-2" v-if="toUpdate != null">
            <div class="">
              <base-label label="Activo" for-label="input-is-active" />
              <radio-button
                v-model="toUpdate.isActive"
                :options="isActiveOptions"
                id="input-is-active"
                button-class="!py-2"
              />
            </div>
            <div class="">
              <base-label label="Staff de plataforma" for-label="input-is-staff" />
              <radio-button
                v-model="toUpdate.isStaff"
                :options="isStaffOptions"
                id="input-is-staff"
                button-class="!py-2"
              />
            </div>
          </div>

          <div class="mt-4 text-center">
            <button-secondary
              ><span v-if="wopoUserId != null">Guardar</span><span v-else>Crear</span>
            </button-secondary>
          </div>
        </div>
      </form>
    </base-card>
  </div>
</template>
