import { DateTime, Duration } from "luxon";

export function formatDate(value) {
  const date = dateFromISO(value);
  // luxon toLocaleString https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring
  return date.setLocale("es-ES").toLocaleString({ year: "numeric", month: "2-digit", day: "2-digit" });
}

export function formatDateTime(value) {
  const date = dateFromISO(value);
  // luxon toLocaleString https://moment.github.io/luxon/api-docs/index.html#datetimetolocalestring
  return date
    .setLocale("es-ES")
    .toLocaleString({ year: "numeric", month: "2-digit", day: "2-digit", hour: "2-digit", minute: "2-digit" });
}

export function daysBetween(startDate, endDate) {
  startDate = convertToLuxonDate(startDate);
  endDate = convertToLuxonDate(endDate);
  return `${Math.round(endDate.diff(startDate, "days").days)}d`;
}

function convertToLuxonDate(value) {
  if (typeof value === "string") {
    return dateFromISO(value);
  } else if (Object.prototype.toString.call(value) === "[object Date]") {
    return DateTime.fromJSDate(value);
  }
  return value;
}

export function dateFromISO(value) {
  return DateTime.fromISO(value);
}

export function toHuman(seconds) {
  return Duration.fromObject({ seconds: seconds }).toFormat("hh:mm:ss");
}

export function formatMoney(money) {
  if (money === undefined || money === null || isNaN(money)) {
    return "-\xa0€";
  }
  const formatter = new Intl.NumberFormat("es-ES", {
    style: "currency",
    currency: "EUR",
  });
  // server return money in cents
  return formatter.format(money / 100);
}

export function formatNumber(number) {
  if (number === undefined || number === null || isNaN(number)) {
    return "";
  }
  const formatter = new Intl.NumberFormat("es-ES", {
    maximumFractionDigits: 2,
  });
  return formatter.format(number);
}

export function isNumber(value) {
  return !isNaN(Number(value));
}

export function formatRecordNumbers(value) {
  if (value === undefined || value === null || value === "") {
    return "-";
  }
  return `#${value.toString().padStart(5, "0")}`;
}

export function formatNumberOfAttempts(value, row) {
  if (row["wasPublishedAtSomePoint"]) {
    return value;
  }
  return "-";
}

/* FORMATTERS */
export function stringArrayFormatter(value) {
  return value.join(", ");
}

export function yesNoCapitalizedFormatter(value) {
  return capitalizeFirstLetter(yesNoFormatter(value));
}

export function yesNoFormatter(value) {
  return value ? "sí" : "no";
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function buildKeyFormatter(key) {
  return function (value) {
    if (value != null) {
      return value[key];
    }
    return "";
  };
}

export function buildKeyAndArrayFormatter(...args) {
  return function (value) {
    const output = [];
    for (const element of value) {
      let obj_value = element;
      args.forEach((arg) => {
        obj_value = obj_value[arg];
      });
      output.push(obj_value);
    }
    return output.join(", ");
  };
}

// Constants
export const pageSizeOptions = [
  { label: "5", value: 5 },
  { label: "10", value: 10 },
  { label: "20", value: 20 },
  { label: "30", value: 30 },
  { label: "40", value: 40 },
  { label: "50", value: 50 },
];
export const EXAM_TITLES = {
  previousCall: "Test oficiales",
  alleged: "supuestos prácticos",
  allegedPreviousCall: "supuestos prácticos oficiales",
};

export const MODE_CREATE = "create";
export const MODE_EDIT = "edit";
export const MODE_DUPLICATE = "duplicate";

export const ATTEMPT_CUSTOM = "custom";
export const ATTEMPT_BY_GENERAL_PROCESAL = "by_general_procesal";
export const ATTEMPT_CUSTOM_FAVOURITES = "custom_favourites";
export const ATTEMPT_CUSTOM_BLANK = "custom_blank";
export const ATTEMPT_CUSTOM_INCORRECT = "custom_incorrect";
export const ATTEMPT_CUSTOM_UNCERTAIN = "custom_uncertain";
export const ATTEMPT_PREVIOUS_CALL = "previous_call";
export const ATTEMPT_PREVIOUS_CALL_CC = "previousCall";
export const ATTEMPT_PRACTICAL_CASE = "practical_case";
export const ATTEMPT_PRACTICAL_CASE_CC = "practicalCase";
export const ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL = "practical_case_previous_call";
export const ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL_CC = "practicalCasePreviousCall";

export const ATTEMPT_TYPE_OPTIONS = [
  { label: "personalizado", value: ATTEMPT_CUSTOM },
  { label: "simulacro oficiales: test", value: ATTEMPT_PREVIOUS_CALL },
  { label: "supuesto práctico", value: ATTEMPT_PRACTICAL_CASE },
  { label: "simulacro oficiales: supuesto práctico", value: ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL },
];
export const ATTEMPT_TYPE_TITLES = [
  {
    title: "Personaliza tu test por ley o por tema",
    value: ATTEMPT_CUSTOM,
    shortTitle: "Por ley o tema",
    category: "Configuración",
  },
  {
    title: "Atrévete a repasar por parte general o parte procesal",
    value: ATTEMPT_BY_GENERAL_PROCESAL,
    shortTitle: "General o procesal",
    category: "Configuración",
  },
  { title: "Preguntas falladas", value: ATTEMPT_CUSTOM_INCORRECT, shortTitle: "Falladas", category: "Preguntas" },
  { title: "Preguntas en blanco", value: ATTEMPT_CUSTOM_BLANK, shortTitle: "En blanco", category: "Preguntas" },
  { title: "Preguntas dudosas", value: ATTEMPT_CUSTOM_UNCERTAIN, shortTitle: "Dudosas", category: "Preguntas" },
  {
    title: "Tus preguntas favoritas",
    value: ATTEMPT_CUSTOM_FAVOURITES,
    shortTitle: "Favoritas",
    category: "Preguntas",
  },
  {
    title: "Entrena con tests oficiales",
    value: ATTEMPT_PREVIOUS_CALL,
    shortTitle: "Tests oficiales",
    category: "Entrenamiento",
  },
  { title: "Supuestos prácticos", value: ATTEMPT_PRACTICAL_CASE, shortTitle: "Prácticos", category: "Entrenamiento" },
  {
    title: "Supuestos prácticos oficiales",
    value: ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL,
    shortTitle: "Prácticos oficiales",
    category: "Entrenamiento",
  },
];

export const EXAM_CATEGORY_OPTIONS = [
  { label: "Todas", value: null },
  { label: "Parte General", value: "parte general" },
  { label: "Orden Civil", value: "orden civil" },
  { label: "Orden Penal", value: "orden penal" },
  { label: "Orden Administrativo", value: "orden administrativo" },
  { label: "Orden Social", value: "orden social" },
];

export const DIFFICULTY_OPTIONS = [
  { label: "Fáciles", value: "easy", description: "Preguntas fáciles, para empezar con buen pie" },
  { label: "Aleatoria / Mixta", value: null, description: "Mezcladito: un poco de todo, aleatorio" },
  { label: "Difíciles", value: "hard", description: "Preguntas difíciles, no llega con estudiar, hay que entender" },
];
export const QUESTION_SUBTYPE_OPTIONS = [
  { label: "Ambas", value: "ambas" },
  { label: "Parte procesal", value: "procesal" },
  { label: "Parte general", value: "general" },
];
export const QUESTION_TYPE_OPTIONS = [
  { label: "todas", value: null },
  { label: "dudosas", value: "is_uncertain" },
  { label: "incorrectas", value: "waw_incorrect" },
  { label: "favoritas", value: "is_starred" },
];

/* events */

export function setupApp(rootId = "root-div", printMessageId = "print-id") {
  function beforePrint() {
    document.getElementById(rootId).style.display = "none";
    document.getElementById(printMessageId).style.display = "";
  }

  function afterPrint() {
    document.getElementById(rootId).style.display = "";
    document.getElementById(printMessageId).style.display = "none";
    // showPrintMessage.value = false;
  }

  if (window.matchMedia) {
    window.matchMedia("print").addListener(function (mql) {
      if (mql.matches) {
        beforePrint();
      } else {
        afterPrint();
      }
    });
  }
  // For IE, does not attach in browsers that do not support these events
  window.addEventListener("beforeprint", beforePrint, false);
  window.addEventListener("afterprint", afterPrint, false);
}

/**
 * Format bytes as human-readable text.
 *
 * @param bytes Number of bytes.
 * @param dp Number of decimal places to display.
 *
 * @return Formatted string.
 */
export function humanFileSize(bytes, dp = 1) {
  const threshold = 1024;

  if (Math.abs(bytes) < threshold) {
    return bytes + " B";
  }

  const units = ["kB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
  let u = -1;
  const r = 10 ** dp;

  do {
    bytes /= threshold;
    ++u;
  } while (Math.round(Math.abs(bytes) * r) / r >= threshold && u < units.length - 1);

  return bytes.toFixed(dp) + " " + units[u];
}
