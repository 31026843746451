<script setup>
import BaseCard from "@/components/BaseCard.vue";
import BaseIcon from "@/components/BaseIcon.vue";

defineProps({
  publicExaminations: {
    type: Array,
    required: true,
  },
});

const oppositionsData = {
  "Auxilio Judicial": { totalQuestions: "13k", totalCases: 92 },
  "Tramitación Procesal TL": { totalQuestions: "14k", totalCases: 89 },
  "Gestión Procesal TL": { totalQuestions: "17k", totalCases: 89 },
  "Tramitación Procesal PI": { totalQuestions: "12k", totalCases: 14 },
  "Ofimática Tramitación Procesal": { totalQuestions: "800", totalCases: 0 },
  "Gestión Procesal PI": { totalQuestions: "10k", totalCases: 15 },
};

function getOppositionData(name) {
  return oppositionsData[name] || { totalQuestions: "0k", totalCases: 0 };
}

const emit = defineEmits(["select"]);

function selectPublicExamination(publicExamination) {
  emit("select", publicExamination);
}

function tags(publicExamination) {
  // some browsers not so old do not support hasOwn so use hasOwnProperty for compatibility
  if (publicExamination.extra == null || !publicExamination.extra.hasOwnProperty("tags")) {
    return [];
  }
  return publicExamination.extra.tags.map((t) => {
    return {
      label: t,
    };
  });
}
</script>

<template>
  <base-card class="w-full">
    <div class="test-main-grid">
      <h3 class="mb-7 ml-5 mt-3 text-xl font-medium md:text-2xl">Elige tu oposición</h3>
      <div
        v-for="(publicExamination, index) in publicExaminations"
        :key="index"
        class="test-main-grid-item m-4 flex cursor-pointer flex-col rounded-lg bg-background px-6 py-4 hover:bg-background-hover"
        @click.prevent="selectPublicExamination(publicExamination)"
      >
        <div
          v-for="(tag, tagIndex) in tags(publicExamination)"
          :key="tagIndex"
          class="sm:mb- test-item-pill mb-2 ml-0 w-fit shrink-0 rounded-full bg-white px-3 py-1 align-middle text-xs font-medium uppercase tracking-wider"
          :class="'Turno libre' === tag.label ? 'text-primary-900' : 'text-red-200'"
        >
          {{ tag.label }}
        </div>
        <div class="flex">
          <div class="flex flex-col">
            <div class="mr-4 flex h-14 w-14 shrink-0 items-center">
              <base-icon
                v-if="publicExamination.userHasMembership"
                class="p-0"
                icon-name="basculacv"
                :title="publicExamination.shortName"
                :alt="publicExamination.shortName"
              />
              <base-icon
                v-else
                class="p-0"
                icon-name="basculacr"
                :title="publicExamination.shortName"
                :alt="publicExamination.shortName"
              />
            </div>
            <div class="mb-1 flex flex-col text-xl font-medium text-secondary-800 sm:flex-row sm:items-center">
              <span class="mb-2 sm:mb-0">{{ publicExamination.shortName }}</span>
            </div>
            <span class="text-md sm:mb- mb-2 pb-5 font-light text-secondary-800">{{ publicExamination.name }}</span>
          </div>
        </div>
        <div class="mt-auto text-center">
          <div class="flex justify-center space-x-4 border-t border-t-secondary-160 pt-5">
            <div class="flex items-center text-xs !text-secondary-400">
              <base-icon class="mr-2 w-4" icon-name="numquestions"></base-icon>
              {{ getOppositionData(publicExamination.shortName).totalQuestions }} preguntas
            </div>
            <div class="flex items-center text-xs !text-secondary-400">
              <base-icon class="mr-2 w-4" icon-name="numpracticalcase"></base-icon>
              {{ getOppositionData(publicExamination.shortName).totalCases }} casos prácticos
            </div>
          </div>
        </div>
      </div>
    </div>
  </base-card>
</template>
