<script setup>
import { computed, inject, onMounted, ref, watch } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import MainRepository from "@/repositories/MainRepository.js";
import BaseCard from "@/components/BaseCard.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import { useRoute, useRouter } from "vue-router";
import UserInputSelector from "@/components/forms/UserInputSelector.vue";
import { DateTime } from "luxon";
import { formatDate } from "@/components/utils.js";
import StatisticsSelectorTotalThemeList from "@/components/statistics/StatisticsSelectorTotalThemeList.vue";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";
import PageTitle from "@/components/PageTitle.vue";
import PublicExaminationSelect from "@/components/statistics/PublicExaminationSelect.vue";
import { usePublicExaminations } from "@/components/composables/publicExaminations.js";

const { sendServerError } = useToast();

const router = useRouter();
const route = useRoute();

const oppositions = inject("publicExaminations");
const opposition = ref(null);
const oppositionStatistics = ref(null);
const statisticsTime = ref("7");

const endDate = computed(() => {
  const today = DateTime.now();
  return formatDate(today);
});

const startDate = computed(() => {
  let firstDate = DateTime.now();

  if (statisticsTime.value === "7") {
    firstDate = firstDate.minus({ days: 7 });
  } else if (statisticsTime.value === "30") {
    firstDate = firstDate.minus({ days: 30 });
  } else if (statisticsTime.value === "365") {
    firstDate = firstDate.minus({ days: 365 });
  }

  return formatDate(firstDate);
});

const publicExaminationId = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return opposition.value.id;
});

const { userPublicExaminations } = usePublicExaminations(oppositions.value);

const handleOppositionChange = async (selectedOpposition) => {
  try {
    await router.replace({ name: "public-examination-themes-statistics", params: { slug: selectedOpposition.slug } });
  } catch (error) {
    sendServerError(error);
  }
};

const statisticsTimeOptions = [
  { label: "Últimos 365 días", value: "365" },
  { label: "Últimos 30 días", value: "30" },
  { label: "Últimos 7 días", value: "7" },
];

const selectedOrder = ref("default");

onMounted(async () => {
  if (route.query.statisticsTime) {
    statisticsTime.value = route.query.statisticsTime;
  }
  await fetchData(route.params.slug);
});

watch(
  () => route.params.slug,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await fetchData(newValue);
    }
  }
);
const user = inject("user");
const userIdForStatistics = ref(null);

const changeUser = (newUserId) => {
  userIdForStatistics.value = newUserId;
  fetchOppositionStatistics();
};

const fetchData = async (slug) => {
  try {
    if (slug) {
      opposition.value = userPublicExaminations.value.find((item) => item.slug === slug);
    }
    if (!opposition.value && userPublicExaminations.value.length > 0) {
      opposition.value = oppositions.value[0];
    }
    await fetchOppositionStatistics();
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
};

const fetchOppositionStatistics = async () => {
  const slug = route.params.slug;
  if (!slug) {
    return;
  }

  try {
    const response = await MainRepository.publicExaminationStatistics({
      slug,
      timeRange: statisticsTime.value,
      userId: userIdForStatistics.value,
    });
    oppositionStatistics.value = response.data;

    orderStatistics();
  } catch (error) {
    sendServerError(error, "FETCH-OPPOSITION-STATISTICS");
  }
};

const statisticsOrderOptions = [
  { label: "Ordenar por defecto", value: "default" },
  { label: "Ordenar por preguntas falladas (%)", value: "incorrect" },
  { label: "Ordenar por preguntas acertadas (%)", value: "correct" },
  { label: "Ordenar por preguntas en blanco (%)", value: "blank" },
];

const orderStatistics = () => {
  if (!oppositionStatistics.value) return;
  // incorrect case is handled by the server as this is the default order from backend
  if (selectedOrder.value === "blank") {
    oppositionStatistics.value.topFailThemeList.sort((a, b) => {
      const blankPercentageA = 100 - a.incorrectPercentage - a.correctPercentage;
      const blankPercentageB = 100 - b.incorrectPercentage - b.correctPercentage;

      return blankPercentageB - blankPercentageA;
    });
  } else if (selectedOrder.value === "correct") {
    oppositionStatistics.value.topFailThemeList.sort((a, b) => b.correctPercentage - a.correctPercentage);
  } else if (selectedOrder.value === "default") {
    // The list is ordered by the names of the topics, for this the ids are used right now,
    // check this if new topics are created that unorder the list
    oppositionStatistics.value.topFailThemeList.sort((a, b) => a.id[0] - b.id[0]);
  }
};

const hasMostFailedContentLevels = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.topFailThemeList.length > 0;
});
const mostFailedContentLevels = computed(() => {
  if (!oppositionStatistics.value) {
    return null;
  }
  return oppositionStatistics.value.topFailThemeList;
});
</script>

<template>
  <no-header-view>
    <div>
      <div class="test-new-header relative mb-10">
        <img
          src="@/assets/test-heading-background.png"
          class="absolute z-[-1] h-full w-full md:relative"
          aria-hidden="true"
        />
        <div
          class="left-0 top-0 flex h-full w-full flex-col content-end items-start gap-4 p-5 md:absolute md:flex-row md:items-end lg:p-10"
        >
          <div class="flex-grow">
            <img
              src="@/assets/logo-ruth-galvan-horizontal-negro.svg"
              class="test-new-header-logo !w-[80%] md:!w-80"
              alt=""
            />
          </div>
          <div class="test-new-header-details-2 flex flex-col">
            <p class="text-xl font-medium lg:text-2xl">Estadísticas por tema</p>
            <p class="text-sm font-normal 2xl:text-base">Revisa tus estadísticas por tema</p>
          </div>
        </div>
      </div>

      <base-card class="custom-shadow mx-0 w-fit rounded-3xl border-0 p-2 drop-shadow-none">
        <div class="flex gap-4">
          <base-icon class="p-0" icon-name="basculacv" />
          <div>
            <public-examination-select
              v-model="opposition"
              :public-examinations="oppositions"
              @update:model-value="handleOppositionChange"
              class="!p-0 !shadow-none"
            />
          </div>
        </div>
      </base-card>

      <base-card
        v-if="user !== null && user.isSuperuser"
        class="custom-shadow my-0 w-full rounded-3xl border-0 bg-admin p-8 drop-shadow-none"
      >
        <div class="w-full">
          <base-label label="Cambiar usuario" for-label="user-id" />
          <user-input-selector id="user-id" @user-id="changeUser" />
        </div>
      </base-card>

      <base-card class="custom-shadow-only-desktop !mx-0 mt-8 w-full rounded-3xl border-0 p-0 drop-shadow-none md:p-8">
        <div class="flex flex-col xl:flex-row xl:gap-4">
          <div class="mb-5 mt-3 flex flex-col">
            <p v-if="opposition" class="text-3xl font-medium text-secondary-800">{{ opposition.shortName }}</p>
          </div>
          <div
            class="flex flex-grow flex-wrap items-start justify-between gap-2 xl:flex-nowrap xl:items-center xl:justify-end xl:gap-2"
          >
            <div class="order-2 ml-0 xl:order-1">
              <base-select
                v-model="selectedOrder"
                id="order-test-statistics"
                name="order-test-statistics"
                :options="statisticsOrderOptions"
                @change="fetchOppositionStatistics"
                class="border-0 !bg-[#ECEEFB]"
              />
            </div>
            <p class="order-3 ml-auto w-full text-base font-normal text-[#031E33] xl:order-2 xl:w-auto">
              Del {{ startDate }} al {{ endDate }}
            </p>
            <div class="order-1 xl:order-3">
              <base-select
                v-model="statisticsTime"
                id="select-statistics-time"
                name="select-statistics-time"
                :options="statisticsTimeOptions"
                @change="fetchOppositionStatistics"
                class="border-0 !bg-[#ECEEFB]"
              />
            </div>
          </div>
        </div>
        <div>
          <div class="mb-5 mt-3 flex flex-col">
            <p class="text-xl font-medium text-secondary-800">Resultados de test realizados por tema</p>
            <p class="text-base font-light text-secondary-800">
              Estos porcentajes corresponden solo a los test personalizados por ley, tema o parte general/procesal
            </p>
          </div>
          <div class="flex flex-row">
            <p
              v-if="!hasMostFailedContentLevels && opposition"
              class="text-md p-16 text-center font-light text-secondary-800"
            >
              No se encontraron temas para la oposición {{ opposition.shortName }}.
            </p>
            <div v-else class="flex w-1/2 flex-grow flex-col">
              <statistics-selector-total-theme-list
                v-if="mostFailedContentLevels"
                :content-levels="mostFailedContentLevels"
                :public-examination-id="publicExaminationId"
              />
            </div>
          </div>
        </div>
      </base-card>
    </div>
  </no-header-view>
</template>
