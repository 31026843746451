<script setup>
import { computed, inject, onMounted, ref } from "vue";
import ButtonRouterLink from "@/components/buttons/ButtonRouterLink.vue";
import BaseCard from "@/components/BaseCard.vue";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import MainRepository from "@/repositories/MainRepository.js";
import PageTitle from "@/components/PageTitle.vue";

const user = inject("user");
const appLoading = inject("appLoading");
const step = ref(1);

const without_review = ref({
  questions: null,
  tests: null,
  practical: null,
  practicalOfficial: null,
});

const urls = computed(() => {
  return [
    [
      {
        title: "Banco de preguntas",
        to: { name: "imported-questions" },
        number_of_not_reviewed: without_review.value.questions,
      },
      {
        title: "Test oficiales",
        to: { name: "exam-list-previous-call" },
        number_of_not_reviewed: without_review.value.tests,
      },
    ],
    [
      {
        title: "Supuestos prácticos",
        to: { name: "exam-list-case-study" },
        number_of_not_reviewed: without_review.value.practical,
      },
      {
        title: "Supuesto prácticos oficiales",
        to: { name: "exam-list-case-study-previous-call" },
        number_of_not_reviewed: without_review.value.practicalOfficial,
      },
      {
        title: "Importar preguntas de un documento",
        to: { name: "import-questions" },
      },
    ],
  ];
});

const isLoading = computed(() => {
  if (appLoading.value == null) return false;
  return appLoading.value;
});

onMounted(async () => {
  await getNotReviewed();
});

async function getNotReviewed() {
  if (user.value === null || !user.value.isStaff) {
    return;
  }

  // use limit 1 because we are only interested in count to know how much they are
  // this avoids to make n+1 queries on the backend
  try {
    let response = await MainRepository.questionList({
      reviewed: 0,
      without_exams: true,
      limit: 1,
    });
    without_review.value.questions = response.data.count;

    response = await MainRepository.examList({
      reviewed: 0,
      exam_type: "previousCall",
      limit: 1,
    });
    without_review.value.tests = response.data.count;

    response = await MainRepository.examList({
      reviewed: 0,
      exam_type: "alleged",
      limit: 1,
    });
    without_review.value.practical = response.data.count;

    response = await MainRepository.examList({
      reviewed: 0,
      exam_type: "allegedPreviousCall",
      limit: 1,
    });
    without_review.value.practicalOfficial = response.data.count;
  } catch (error) {
    console.error(error);
  }
}
</script>

<template>
  <no-header-view>
    <fade-transition>
      <div>
        <page-title>Herramientas de revisión</page-title>
        <base-card
          v-if="user !== null && user.isStaff && !isLoading && step === 1"
          class="!mx-0 w-full border-0 !p-0 drop-shadow-none"
        >
          <div v-for="(elements, index) in urls" :key="index">
            <div
              class="custom-shadow m-4 flex items-baseline justify-between rounded-xl px-5 py-4"
              v-for="(value, subIndex) in elements"
              :key="subIndex"
            >
              <div class="grid w-1/2 grid-cols-2 grid-rows-1 gap-5">
                <span :class="{ 'col-span-2': !value.number_of_not_reviewed }">{{ value.title }}</span>
                <span v-if="value.number_of_not_reviewed" class="text-secondary-350">
                  {{ value.number_of_not_reviewed }} sin revisar
                </span>
              </div>
              <button-router-link
                :to="value.to"
                class="mx-4 h-full rounded-lg bg-primary-800 !px-6 !py-2 text-center text-sm text-white hover:!bg-[#F6B34D]"
              >
                Acceder
              </button-router-link>
            </div>
          </div>
        </base-card>
      </div>
    </fade-transition>
  </no-header-view>
</template>
