<script setup>
import {
  ATTEMPT_BY_GENERAL_PROCESAL,
  ATTEMPT_CUSTOM,
  ATTEMPT_TYPE_TITLES,
  DIFFICULTY_OPTIONS,
} from "@/components/utils.js";
import AttemptSelectorHeaderElement from "@/components/attempt-selector/AttemptSelectorHeaderElement.vue";
import { computed } from "vue";
import { attemptTypeIconSelector } from "@/components/icons.js";
import BaseIcon from "@/components/BaseIcon.vue";
import { useRouter } from "vue-router";
import MiniLoading from "@/components/mini-loading.vue";
import ButtonPrimaryWithArrow from "@/components/buttons/ButtonPrimaryWithArrow.vue";

const router = useRouter();

const props = defineProps({
  publicExamination: {
    type: Object,
    required: true,
  },
  selectedType: {
    type: [String, null],
    required: true,
  },
  selectedDifficulty: {
    type: [String, null],
    required: true,
  },
  questions: {
    type: [Number, null],
    required: true,
  },
  selectedContents: {
    type: [Object, null],
    required: true,
  },
  selectedExam: {
    type: [Object, null],
  },
  showCreateButton: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const typeTittle = computed(() => {
  const result = ATTEMPT_TYPE_TITLES.find((obj) => obj.value === props.selectedType);
  return result ? result.shortTitle || result.title : "";
});

const typeCategory = computed(() => {
  const result = ATTEMPT_TYPE_TITLES.find((obj) => obj.value === props.selectedType);
  return result ? result.category || "Oposición" : "Oposición";
});

const selectIcon = computed(() => {
  return attemptTypeIconSelector(props.selectedType, true);
});

const isCustomAttempt = computed(() => {
  return props.selectedType != null ? props.selectedType.includes(ATTEMPT_CUSTOM) : false;
});

const isGeneralProcesal = computed(() => {
  return props.selectedType != null ? props.selectedType.includes(ATTEMPT_BY_GENERAL_PROCESAL) : false;
});

const difficultyTitle = computed(() => {
  for (let i = 0; i < DIFFICULTY_OPTIONS.length; i++) {
    if (props.selectedDifficulty === DIFFICULTY_OPTIONS[i].value) {
      return DIFFICULTY_OPTIONS[i].label;
    }
  }
  return "";
});

const computedElements = computed(() => {
  const output = [
    {
      callback: selectPublicExamination,
      title: props.publicExamination.name,
      description: "Esta es la oposición de la que realizarás el test",
      icon: "bascula",
      iconTitle: props.publicExamination.name,
      buttonText: "Elegir otra oposición",
      show: true,
      isLast: props.selectedType == null,
      category: "Oposición",
    },
  ];
  if (props.selectedType != null) {
    output.push({
      callback: selectType,
      title: typeTittle.value,
      description: "Este es el tipo de test que realizarás",
      icon: selectIcon.value,
      iconTitle: selectIcon.value,
      buttonText: "Elegir otro test",
      isLast: !(
        (isCustomAttempt.value && props.questions != null) ||
        (!isCustomAttempt.value && props.selectedExam != null)
      ),
      category: typeCategory.value,
    });
  }

  if ((isCustomAttempt.value || isGeneralProcesal.value) && props.questions != null) {
    output.push(
      {
        callback: selectParams,
        title: props.questions,
        mainTitle: "Preguntas",
        description: "Este es el número total de preguntas que tendrá el test",
        icon: "npreguntas",
        iconTitle: "n preguntas",
        buttonText: "Elegir otra cantidad",
        isLast: false,
      }
      // {
      //   callback: selectParams,
      //   title: difficultyTitle.value,
      //   description: "Esta es la dificultad que has seleccionado para el test",
      //   icon: "dificultad",
      //   iconTitle: "dificultad",
      //   buttonText: "Elegir otra dificultad",
      //   isLast: false,
      // },
    );

    if (isCustomAttempt.value) {
      output.push({
        callback: selectParams,
        title: "Contenido del test",
        mainTitle: "Contenidos",
        description: "Este es el contenido que has seleccionado para generar preguntas en el test",
        icon: "contenidos",
        iconTitle: "contenidos",
        buttonText: "Elegir otros contenidos",
        isLast: true,
      });
    }
  }
  if (!isCustomAttempt.value && props.selectedExam != null) {
    output.push({
      callback: selectExam,
      title: props.selectedExam.title,
      description: "Este es el examen del que realizarás el test",
      icon: "exam",
      iconTitle: "examen",
      buttonText: "Elegir otro examen",
      isLast: true,
    });
  }
  return output;
});

const emit = defineEmits([
  "unselect-public-examination",
  "unselect-type",
  "unselect-exam",
  "unselect-params",
  "create",
]);

function selectPublicExamination() {
  emit("unselect-public-examination");
}

function selectType() {
  emit("unselect-type");
}

function selectExam() {
  emit("unselect-exam");
}

function selectParams() {
  emit("unselect-params");
}

function move() {
  emit("create");
}
</script>

<template>
  <div class="opp-conf-current">
    <div class="flex flex-wrap gap-4">
      <attempt-selector-header-element
        v-for="(element, index) in computedElements"
        :key="index"
        :callback="element.callback"
        :is-last="element.isLast"
        class="opp-conf-current-card w-72 rounded-2xl bg-white p-4 pb-5 pr-5"
      >
        <template v-slot:icon>
          <base-icon :icon-name="element.icon" :title="element.iconTitle" :alt="element.iconTitle" class="h-14 w-14" />
        </template>
        <div class="flex flex-col justify-center gap-1">
          <p class="text-base font-semibold">{{ element.category || element.mainTitle }}</p>
          <p class="overflow-hidden text-ellipsis whitespace-nowrap text-base font-normal">{{ element.title }}</p>
        </div>
      </attempt-selector-header-element>
    </div>
    <div v-if="showCreateButton" class="mb-4 ml-3 mt-6 flex w-full flex-col items-start">
      <h2 class="mb-4 mt-8 text-2xl md:text-3xl">¡Empieza ya tu test!</h2>
      <p class="mb-8">Esta es la configuración de tu test personalizado.</p>
      <button-primary-with-arrow @click="move" :disabled="disabled">
        Empezar test
        <template #after-icon>
          <div v-show="disabled">
            <mini-loading />
          </div>
        </template>
      </button-primary-with-arrow>
    </div>
  </div>
</template>
