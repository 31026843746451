<script setup>
import { computed, onMounted, onUpdated, ref } from "vue";

const props = defineProps({
  radius: {
    type: Number,
    default: 30,
  },
  padding: {
    type: Number,
    default: 10,
  },
  value: {
    type: Number,
    default: 0,
  },
  percentage: {
    type: Number,
    default: 0.33,
  },
  percentageColor: {
    type: String,
    default: "#C4D600",
  },
  showPercentage: {
    type: Boolean,
    default: true,
  },
});

const integerPercentage = computed(() => Math.round(props.percentage * 100));

function CanvasManager() {
  this.ctx = null;

  this.setContext = function (ctx) {
    this.ctx = ctx;
  };

  this.drawCircle = function (ctx, color = "#E6EBEE", fill = false) {
    let radius = props.radius;
    if (fill) {
      radius *= 0.7;
    }
    ctx.beginPath();
    ctx.arc(props.radius + props.padding / 2, props.radius + props.padding / 2, radius, 0, 2 * Math.PI);
    ctx.imageSmoothingEnabled = true;
    if (fill) {
      ctx.fillStyle = color;
      ctx.fill();
    } else {
      ctx.strokeStyle = color;
      ctx.lineWidth = 10;
      ctx.stroke();
    }
  };

  this.drawPercentageArc = function (ctx) {
    const x = props.radius + props.padding / 2;
    const y = props.radius + props.padding / 2;

    // gradiente
    const gradient = ctx.createConicGradient(1.5 * Math.PI, x, y);
    gradient.addColorStop(0, "#E6EBEE");
    gradient.addColorStop(0.2, props.percentageColor);

    ctx.beginPath();
    ctx.arc(x, y, props.radius, 1.5 * Math.PI, 1.5 * Math.PI + 2 * Math.PI * props.percentage);
    ctx.strokeStyle = gradient;
    ctx.lineWidth = 10;
    ctx.stroke();
  };

  this.drawResult = function () {
    const ctx = this.ctx;
    this.drawCircle(ctx);
    this.drawPercentageArc(ctx);
    this.drawCircle(ctx, "#ffffff", true);

    ctx.beginPath();
    ctx.font = "16px outfit";
    ctx.fillStyle = "#031E33";
    ctx.textAlign = "center";
    ctx.textBaseline = "middle";
    if (props.showPercentage) {
      ctx.fillText(integerPercentage.value + "%", props.radius + props.padding / 2, props.radius + props.padding / 2);
    } else {
      ctx.fillText(props.value, props.radius + props.padding / 2, props.radius + props.padding / 2);
    }
  };
}

let manager = new CanvasManager();

const canvas = ref(null);

function draw() {
  manager.drawResult();
}

onMounted(() => {
  manager.setContext(canvas.value.getContext("2d"));
  draw();
});
onUpdated(draw);
</script>
<template>
  <canvas
    ref="canvas"
    :width="radius * 2 + padding"
    :height="radius * 2 + padding"
    tabindex="0"
    class="bg-white"
  ></canvas>
</template>
