<script setup>
import { computed } from "vue";
import Match from "lodash";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  correctAnswers: {
    type: Number,
    default: 0,
  },
  incorrectAnswers: {
    type: Number,
    default: 0,
  },
  totalAnswers: {
    type: Number,
    default: 0,
  },
});

const blankAnswers = computed(() => {
  return props.totalAnswers - (props.correctAnswers + props.incorrectAnswers);
});

const percentageCorrect = computed(() => {
  if (props.totalAnswers === 0) {
    return 0;
  } else {
    return Match.round((props.correctAnswers / props.totalAnswers) * 100);
  }
});

const percentageError = computed(() => {
  if (props.totalAnswers === 0) {
    return 0;
  } else {
    return Match.round((props.incorrectAnswers / props.totalAnswers) * 100);
  }
});

const percentageBlank = computed(() => {
  if (props.totalAnswers === 0) {
    return 0;
  } else {
    return Match.round((blankAnswers.value / props.totalAnswers) * 100);
  }
});
</script>

<template>
  <div class="mb-4 flex items-center rounded-lg bg-[#F7F8FF] px-5 py-4">
    <div class="flex w-full flex-col">
      <div class="mb-3 flex flex-row justify-between">
        <p class="text-lg font-semibold text-secondary-800">
          {{ title }}
        </p>
        <slot name="actions"></slot>
      </div>
      <slot name="description"></slot>
      <div class="relative flex items-center">
        <div class="mb-2 flex h-1.5 w-full flex-row rounded-full bg-white">
          <div
            class="estadisticas-barrita-verde h-1.5 rounded bg-[#52E684]"
            data-tip="{{ percentageCorrect }}%"
            :style="{ width: percentageCorrect + '%' }"
          />
          <div
            class="tooltip estadisticas-barrita-roja h-1.5 rounded bg-[#FF5869]"
            data-tip="{{ percentageError }}%"
            :style="{ width: percentageError + '%' }"
          />
        </div>
      </div>

      <div class="mt-2 flex w-full justify-between">
        <p class="flex gap-1 text-[#031E33]">
          <span class="text-[#52E684]">{{ percentageCorrect }}%</span>
          <span>|</span>
          <span class="text-[#FF5869]">{{ percentageError }}%</span>
          <span>|</span>
          <span class="text-[#031E33]">{{ percentageBlank }}%</span>
        </p>
        <p class="flex gap-2 text-[#031E33]">
          <span class="mr-3 text-secondary-800">Respuestas:</span>
          <span class="text-[#52E684]">{{ correctAnswers }}</span>
          <span>|</span>
          <span class="text-[#FF5869]">{{ incorrectAnswers }}</span>
          <span>|</span>
          <span class="text-[#031E33]">{{ blankAnswers }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
