<script setup>
import { useWS } from "@/components/composables/ws-call.js";
import BaseCard from "@/components/BaseCard.vue";
import { computed, inject, onMounted, ref } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import { cloneDeep } from "lodash";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import PhoneInput from "@/components/forms/PhoneInput.vue";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import MainRepository from "@/repositories/MainRepository.js";
import BaseInputDocument from "@/components/BaseInputDocument.vue";
import ButtonLink from "@/components/buttons/ButtonLink.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import PageTitle from "@/components/PageTitle.vue";
import { humanFileSize } from "@/components/utils.js";
import BaseIcon from "@/components/BaseIcon.vue";

const { sendError, sendServerError, sendWarnNotification, sendNotification } = useToast();

const onAction = ref(false);
const user = inject("user");
const toUpdate = ref(null);
const avatarHelp = ref("");

const avatarImageTypeHelpOpen = ref(false);
const avatarImageType = ref("");

const avatarHolder = ref(null);
const newAvatar = ref(null);
const avatarError = ref("");
const newAvatarURL = computed(() => {
  if (newAvatar.value === null) {
    return null;
  }
  return URL.createObjectURL(newAvatar.value);
});
const isAvatarScheduledForDelete = ref(false);
const deleteAvatar = async () => {
  if (user.value === null) {
    return;
  }
  newAvatar.value = null;
  user.value.avatarUrl = null;
  isAvatarScheduledForDelete.value = true;
  isDeleteOpen.value = false;
};

const isDeleteOpen = ref(false);

const { callWs: updateUserProfile, erroMsg: profileUpdateErrors } = useWS(
  MainRepository.updateMyUserProfile(user.value.wopocampusId)
);

onMounted(async () => {
  if (user.value === null) {
    return;
  }
  try {
    const response = await MainRepository.getProfile(user.value.wopocampusId);
    avatarHelp.value = `Máximo ${humanFileSize(response.data.profileMaxSize)}`;
    avatarImageType.value = response.data.profileImageType;
    toUpdate.value = { ...cloneDeep(user.value) };
  } catch (e) {
    sendServerError(e, "IN-INIT");
  }
});

async function updateProfile() {
  if (user.value === null) {
    return;
  }
  try {
    await updateUserProfile({ ...toUpdate.value });
    sendNotification("success", "Éxito", "Datos de usuario guardados correctamente");
  } catch (e) {
    if (e.response && e.response.data) {
      let errors = "";
      if (e.response.data.username) {
        errors += e.response.data.username.join(",");
        errors += "\n";
      }
      if (e.response.data.email) {
        errors += e.response.data.email.join(",");
        errors += "\n";
      }
      sendError(errors);
    } else {
      sendServerError(e, "IN-INIT");
    }
  }
  if (newAvatar.value) {
    avatarError.value = "";
    try {
      await MainRepository.saveMyUserAvatar(user.value.wopocampusId, newAvatar.value);
      newAvatar.value = null;
      const response = await MainRepository.me();
      user.value = response.data;
    } catch (e) {
      if (e.response?.data?.unknown?.avatar) {
        avatarError.value = e.response?.data?.unknown?.avatar ?? "";
        scrollToSelector("#foto-form");
        sendWarnNotification("Error en la foto de la pregunta", avatarError.value);
      } else {
        sendServerError(e, "QST-IMG-SAV");
      }
      onAction.value = false;
      return;
    }
  } else if (isAvatarScheduledForDelete.value) {
    try {
      await MainRepository.deleteMyUserAvatar(user.value.wopocampusId);
      isAvatarScheduledForDelete.value = false;
      const response = await MainRepository.me();
      user.value = response.data;
    } catch (e) {
      sendServerError(e, "QST-IMG-DEL");
      onAction.value = false;
    }
  }
}

function scrollToSelector(selector) {
  const element = document.querySelector(selector);
  if (!element) {
    console.error(`Element with selector "${selector}" not found`);
    return;
  }
  element.scrollIntoView({
    block: "center",
  });
}
</script>
<template>
  <title-header-view>
    <div class="test-new-header relative mb-10">
      <img
        src="@/assets/test-heading-background.png"
        class="absolute z-[-1] h-full w-full md:relative"
        aria-hidden="true"
      />
      <div
        class="left-0 top-0 flex h-full w-full flex-col content-end items-start gap-4 p-5 md:absolute md:flex-row md:items-end lg:p-10"
      >
        <div class="flex-grow">
          <img
            src="@/assets/logo-ruth-galvan-horizontal-negro.svg"
            class="test-new-header-logo !w-[80%] md:!w-80"
            alt=""
          />
        </div>
        <div class="test-new-header-details-2 flex flex-col">
          <p class="text-xl font-medium lg:text-2xl">Mi cuenta</p>
          <p class="text-sm font-normal 2xl:text-base">Información de tu perfil</p>
        </div>
      </div>
    </div>
    <div>
      <base-card class="custom-shadow !mx-0 mt-16 w-full rounded-3xl border-0 p-8 drop-shadow-none">
        <h2 class="mb-6 text-3xl">Ajustes del perfil</h2>
        <form @submit.prevent="updateProfile">
          <div id="foto-form" class="bg grid gap-4 rounded-lg !bg-[#F7F8FF] px-4 py-5 sm:px-6 md:grid-cols-3">
            <dt class="flex flex-row gap-2">
              <div class="flex flex-col">
                <div div class="flex flex-row items-center gap-2">
                  <p class="text-secondary-60 mb-1a text-lg font-medium">Avatar</p>
                  <base-icon
                    title="Ayuda"
                    icon-name="question"
                    class="h-6 w-6 cursor-pointer"
                    alt="Ayuda"
                    @click.prevent="avatarImageTypeHelpOpen = true"
                  />
                  <base-dialog :is-open="avatarImageTypeHelpOpen">
                    <template v-slot:default>
                      <p>Puedes usar los siguientes formatos de imágenes:</p>
                      <p>{{ avatarImageType }}</p>
                    </template>
                    <template v-slot:buttons>
                      <button-primary @click="avatarImageTypeHelpOpen = false">Entendido</button-primary>
                    </template>
                  </base-dialog>
                </div>
                <p class="text-base font-light">
                  Esta es la imagen que se mostrará de perfil. Selecciona qué imagen usar.
                </p>
              </div>
            </dt>
            <dd class="mt-1 flex flex gap-8 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
              <div class="flex aspect-square w-full max-w-[136px] flex-col items-center">
                <img
                  ref="avatarHolder"
                  v-if="newAvatar"
                  :src="newAvatarURL"
                  class="w-full max-w-[136px] rounded-3xl"
                  alt="Avatar del usuario"
                />
                <img
                  ref="avatarHolder"
                  v-else-if="user.avatarUrl"
                  :src="user.avatarUrl"
                  class="w-full max-w-[136px] rounded-3xl"
                  alt="Avatar del usuario"
                />
              </div>
              <div class="mt-1 flex flex-grow flex-col items-center justify-center gap-2 rounded-lg bg-white">
                <base-input-document
                  v-bind="newAvatar"
                  ref="documentInput"
                  name="document"
                  extraInputClass="w-auto"
                  :required="true"
                  :help-text="avatarHelp"
                  @change="newAvatar = $event"
                  :error="avatarError"
                />
              </div>
            </dd>
          </div>
          <div class="mt-8 flex flex-col">
            <div
              class="bg grid gap-4 rounded-lg !bg-[#F7F8FF] px-4 py-5 sm:px-6 md:grid-cols-3"
              v-if="toUpdate != null"
            >
              <div class="flex flex-row gap-2">
                <div class="flex flex-col">
                  <div div class="flex flex-row items-center gap-2">
                    <p class="text-secondary-60 mb-1a text-lg font-medium">Tus datos</p>
                  </div>
                  <p class="text-base font-light">Aquí puedes ver y añadir tus datos personales.</p>
                </div>
              </div>
              <div class="sm-grid-cols-1 col-span-2 grid gap-4 md:grid-cols-2">
                <div class="">
                  <base-label label="Nombre*" for-label="input-first-name" />
                  <base-input
                    class="w-[40rem] border-white bg-white text-secondary-600 invalid:border-red-200 disabled:cursor-not-allowed disabled:bg-[#f2f2f2]"
                    v-model="toUpdate.firstName"
                    id="input-first-name"
                    name="input-first-name"
                    :required="true"
                    :disabled="true"
                  />
                </div>
                <div class="">
                  <base-label label="Apellidos*" for-label="input-last-name" />
                  <base-input
                    class="w-[40rem] border-white bg-white text-secondary-600 invalid:border-red-200 disabled:cursor-not-allowed disabled:bg-[#f2f2f2]"
                    v-model="toUpdate.lastName"
                    id="input-last-name"
                    name="input-last-name"
                    :required="true"
                    :disabled="true"
                  />
                </div>
                <div class="">
                  <base-label label="Email*" for-label="input-email" />
                  <base-input
                    class="w-[40rem] border-white bg-white text-secondary-600 invalid:border-red-200 disabled:cursor-not-allowed disabled:bg-[#f2f2f2]"
                    v-model="toUpdate.email"
                    id="input-email"
                    name="input-email"
                    :required="true"
                    :disabled="true"
                  />
                </div>
                <div class="">
                  <base-label label="Teléfono" for-label="input-phone" />
                  <phone-input v-model="toUpdate.phone" :error="profileUpdateErrors?.phone"></phone-input>
                </div>
                <div>
                  <button-secondary
                    class="rounded-lg border-none !bg-[#F39200] !text-base !text-white hover:!bg-primary-with-arrow"
                  >
                    Guardar
                  </button-secondary>
                </div>
              </div>
            </div>
          </div>
        </form>
      </base-card>
    </div>
    <base-dialog :is-open="isDeleteOpen">
      <template v-slot:header>¿Estás seguro de que quieres borrar tu avatar de usuario?</template>
      <template v-slot:buttons>
        <button-primary class="mr-2" @click.prevent="deleteAvatar"> Borrar</button-primary>
        <button-secondary @click="isDeleteOpen = false">Cancelar</button-secondary>
      </template>
    </base-dialog>
  </title-header-view>
</template>
