<script setup>
import BaseCard from "@/components/BaseCard.vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import { usePublicExaminations } from "@/components/composables/publicExaminations.js";

const props = defineProps({
  modelValue: {
    type: [Object, null],
    required: true,
  },
  publicExaminations: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(["update:modelValue"]);

const { userPublicExaminations } = usePublicExaminations(props.publicExaminations);
</script>

<template>
  <base-card class="custom-shadow !mx-0 w-full rounded-3xl border-0 p-8 drop-shadow-none">
    <div class="w-full">
      <base-label label="Oposición" for-label="select-opposition" />
      <base-complex-select
        :model-value="modelValue"
        id="select-opposition"
        name="select-opposition"
        :value-option="(item) => item.id"
        :label-option="(item) => item.name"
        :options="userPublicExaminations"
        @update:model-value="emit('update:modelValue', $event)"
      >
      </base-complex-select>
    </div>
  </base-card>
</template>

<style scoped></style>
