<script setup>
import { RouterView } from "vue-router";
import TheMenu from "./layout-components/TheMenu.vue";
import NotificationsWrapper from "@/components/NotificationsWrapper.vue";
import { inject, onMounted, provide, ref } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import { usePagination } from "@/components/composables/ws-pagination.js";
import MainRepository from "@/repositories/MainRepository.js";
import { setupApp } from "@/components/utils.js";
import TheLoading from "@/components/TheLoading.vue";
import { useVersioning } from "@/components/composables/versioning.js";

const user = inject("user");
const { sendServerError } = useToast();
const { isQA } = useVersioning();
const disableAntiCopyTools = ref(true);
const {
  items: publicExaminations,
  getList: getOppositions,
  isLoading,
} = usePagination(MainRepository.publicExaminationList, 10, 0);

provide("publicExaminations", publicExaminations);
provide("appLoading", isLoading);
onMounted(async () => {
  try {
    await getOppositions({});
  } catch (e) {
    sendServerError(e, "OPO-LIST");
  }
  if (!disableAntiCopyTools.value) {
    setupApp();
  }
});

function handleCopy() {
  if (!disableAntiCopyTools.value) {
    navigator.clipboard.writeText("Academia Ruth Galván! Tus conocimientos puestos a prueba!");
  }
}
</script>

<template>
  <div>
    <div id="root-div" class="min-h-full">
      <!--    <div id="root-div" class="min-h-full" @click.right.prevent @copy.prevent="handleCopy">-->
      <the-menu v-if="!isLoading" :public-examinations="publicExaminations" />
      <the-loading v-else />
      <div class="main flex flex-col" v-if="!isLoading">
        <router-view v-slot="{ Component }">
          <component ref="view" :is="Component" />
        </router-view>
        <div v-if="user && user.version" class="mt-auto p-2 text-right text-slate-300">
          v{{ user.version }}
          <span v-if="isQA">-QA</span>
        </div>
      </div>
      <notifications-wrapper />
    </div>
    <div id="print-id" style="display: none">Impresión desactivada</div>
  </div>
</template>

<style scoped></style>
