<script setup>
defineProps({
  modelValue: {
    type: String,
    default: null,
  },
  id: {
    type: String,
    default: "id-textarea",
  },
  name: {
    type: String,
    default: "textarea",
  },
  rows: {
    type: [Number, String],
    default: 3,
  },
  placeholder: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue", "input"]);
</script>
<template>
  <textarea
    :value="modelValue"
    @change="emit('update:modelValue', $event.target.value)"
    @input="emit('input', $event.target.value)"
    :id="id"
    :name="name"
    :rows="rows"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    class="mt-1 block w-full rounded-md border border-secondary-100 p-2 shadow-sm focus:border-primary-600 focus:ring-primary-600 sm:text-sm"
  ></textarea>
</template>
