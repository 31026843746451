<script setup>
import { useWS } from "@/components/composables/ws-call.js";
import MainRepository from "@/repositories/MainRepository.js";

import { useRoute, useRouter } from "vue-router";
import { computed, inject, onMounted, ref, watch } from "vue";
import { ArrowsUpDownIcon, CheckCircleIcon, XCircleIcon } from "@heroicons/vue/24/outline";

import BaseCard from "@/components/BaseCard.vue";
import BaseTextarea from "@/components/forms/BaseTextarea.vue";
import RichTextarea from "@/components/forms/RichTextarea.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import QuestionDetailDummy from "@/components/imported-questions/QuestionDetailDummy.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import { Sortable } from "sortablejs-vue3";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import { usePagination } from "@/components/composables/ws-pagination.js";
import { humanFileSize, MODE_DUPLICATE, MODE_EDIT, yesNoCapitalizedFormatter } from "@/components/utils";
import { useToast } from "@/components/composables/notifications.js";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import QuestionDetailMultipleSelection from "@/components/imported-questions/QuestionDetailMultipleSelection.vue";
import QuestionOppositionSelection from "@/components/imported-questions/QuestionOppositionSelection.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import ButtonDiscard from "@/components/buttons/ButtonDiscard.vue";
import QuestionFlow from "@/components/imported-questions/QuestionFlow.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import { PUBLICADO, publishedStateFormatter } from "@/components/exams.js";
import ALink from "@/components/buttons/ALink.vue";
import QuestionActionsTooltip from "@/components/imported-questions/QuestionActionsTooltip.vue";
import BaseInputDocument from "@/components/BaseInputDocument.vue";
import ButtonLink from "@/components/buttons/ButtonLink.vue";
import ButtonRouterLink from "@/components/buttons/ButtonRouterLink.vue";

const route = useRoute();
const router = useRouter();
const { sendServerError, sendSuccessNotification, sendError, sendWarnNotification } = useToast();

const { callWs, record } = useWS(MainRepository.questionDetail(route.params.id));
const { callWs: update } = useWS(MainRepository.updateQuestionDetail(route.params.id));
const { callWs: create, record: recordCreate } = useWS(MainRepository.createQuestion());
const { items: laws, getList: getLaws } = usePagination(MainRepository.lawList, 200, 0);

const props = defineProps({
  mode: {
    type: String,
    default: MODE_EDIT,
  },
  emptyOppositionError: {
    type: String,
    default: "Es necesario seleccionar al menos una oposición",
  },
});

const onAction = ref(false);
const selectedLaw = ref(null);
const isEmptyMessageDialogOpen = ref(false);
const markQuestion = ref(false);
const isDocumentationOpen = ref(false);
const isActionsTooltipDialogOpen = ref(false);

const difficultyOptions = [
  { label: "Fácil", value: "easy" },
  { label: "Difícil", value: "hard" },
];

const subtypeOptions = [
  { label: "ninguno", value: "" },
  { label: "parte general", value: "general" },
  { label: "parte procesal", value: "procesal" },
];

const discardQuestionValue = ref("no");
const discardOptions = [
  { label: "no", value: "no" },
  { label: "sí", value: "yes" },
];
const isQuestionMessageDiscarded = computed(() => {
  return discardQuestionValue.value === "yes";
});
watch(record, (newValue) => {
  if (newValue == null) {
    return;
  }
  discardQuestionValue.value = newValue.discarded ? "yes" : "no";
});

const publicExaminationWarningYesNoOptions = [
  { label: "no", value: false },
  { label: "si", value: true },
];

const showPublicExaminationWarningContent = computed(() => {
  return record.value && record.value.publicExaminationWarning;
});

function publicExaminationWarningChanged(value) {
  if (value) {
    return;
  }
  record.value.publicExaminationWarningContent = "";
}

const isEmptyPublicExaminationWarningContent = ref(false);

const allowEdit = computed(() => {
  // As per Ruth request, everything can be edited. 2022/12/22
  // if (props.mode === MODE_EDIT && record.value != null) {
  //   for (const exam of record.value.examsData) {
  //     if (exam.exam.examType === "previousCall" || exam.exam.examType === "allegedPreviousCall") {
  //       return false;
  //     }
  //   }
  // }
  return true;
});

const title = computed(() => {
  if (props.mode === MODE_DUPLICATE) {
    return "Duplicar pregunta";
  }
  return "Detalle de pregunta";
});
const submitButtonTitle = computed(() => {
  if (props.mode === MODE_DUPLICATE) {
    return "Guardar y validar";
  }
  return "Validar";
});

const examTitle = computed(() => {
  if (!isInExam.value) {
    return null;
  }
  return record.value.examsData[0].exam.title;
});

const examState = computed(() => {
  if (!isInExam.value) {
    return null;
  }
  return publishedStateFormatter(record.value.examsData[0].exam.publishedState);
});

const reviewStatus = computed(() => {
  if (record.value != null) {
    if (!isReviewed.value) return "-";
    if (isDiscarded.value) return "Descartada";
    return "Válida";
  }
  return "";
});

const isDiscarded = computed(() => {
  // there are some data in production with questions not reviewed but mark as discarded
  // this method ensures that the data inconsistency is not shown to  the user
  // in the future is data is validated correctly this won't be needed
  if (record.value === null || record.value.lastReviewed === null) {
    return false;
  }
  return record.value.discarded;
});
const isReviewed = computed(() => {
  if (record.value && record.value.lastReviewed) {
    return true;
  }
  return false;
});
const metadataDocumentUrl = computed(() => {
  if (record.value.metadata === null) {
    return null;
  }
  return import.meta.env.VITE_BACKEND_URL + record.value.metadata.document.document;
});
const isInExam = computed(() => {
  return record.value && record.value.examsData.length > 0;
});
const isExamPublished = computed(() => {
  if (!record.value || record.value.examsData.length === 0) {
    return false;
  }
  return record.value.examsData[0].exam.publishedState === PUBLICADO;
});

// image processing
// hold text of help in duplicate mode to explain that duplicate question does not duplicate image
const imageHelp = ref("");
const avatarImageTypeHelpOpen = ref(false);
const avatarImageType = ref("");

const imageHolder = ref(null);
const newImage = ref(null);
const imageError = ref("");
const newImageURL = computed(() => {
  if (newImage.value === null) {
    return null;
  }
  return URL.createObjectURL(newImage.value);
});

const isDeleteOpen = ref(false);
const isImageScheduledForDelete = ref(false);
const deleteImage = async () => {
  if (record.value === null) {
    return;
  }
  newImage.value = null;
  record.value.image = null;
  isImageScheduledForDelete.value = true;
  isDeleteOpen.value = false;
};

// end image processing

onMounted(async () => {
  try {
    await Promise.all([getLaws({}), callWs({})]);

    if (record.value.law != null) {
      selectedLaw.value = { id: record.value.law };
    }

    const maxSizeStr = humanFileSize(record.value.imageMaxSize);
    const maxSizeResolutionStr = `${record.value.imageMaxResolution}X${record.value.imageMaxResolution} píxeles`;
    if (props.mode === MODE_DUPLICATE) {
      record.value.image = null;
      imageHelp.value = `Máximo ${maxSizeStr} y ${maxSizeResolutionStr}.`;
      imageHelp.value += "Por limitaciones del sistema, duplicar pregunta no duplica la foto.";
    } else {
      imageHelp.value = `Máximo ${maxSizeStr} y ${maxSizeResolutionStr}`;
    }
    avatarImageType.value = record.value.imageType;
  } catch (e) {
    sendServerError(e, "INIT-QST");
  }
});

function isCorrectClass(isCorrect) {
  return isCorrect ? "text-primary-800" : "text-secondary-400";
}

function isIncorrectClass(isIncorrect) {
  return isIncorrect ? "text-red" : "text-secondary-400";
}

async function validateQuestion() {
  record.value.markAsReviewed = true;
  record.value.discarded = false;
  try {
    await submit();
  } catch (error) {
    sendServerError(error);
  }
}

async function saveAndMarkQuestionsAsNotReviewed() {
  record.value.markAsReviewed = false;
  record.value.discarded = false;
  try {
    await submit();
  } catch (error) {
    sendServerError(error);
  }
}

async function submit() {
  onAction.value = true;

  if (record.value && record.value.publicExaminationWarning && record.value.publicExaminationWarningContent === "") {
    isEmptyPublicExaminationWarningContent.value = true;
    onAction.value = false;
    return;
  }

  if (record.value.oppositionsData.length <= 0) {
    sendError(props.emptyOppositionError);
    onAction.value = false;
    return;
  }

  let question_id = null;
  let message = "";
  imageError.value = "";
  if (props.mode === MODE_EDIT) {
    question_id = record.value.id;
    try {
      await update(record.value);
      message = "Pregunta guardada correctamente";
      if (record.value.discarded) {
        message = "La pregunta ha sido descartada";
      }
      if (markQuestion.value) {
        message = "Pregunta marcada como no revisada";
      }
    } catch (e) {
      if (hasIncorrectAnswer(e)) {
        sendWarnNotification(
          "¡La pregunta no tiene una respuesta correcta!",
          "<br>Revisa el apartado de respuestas y marca una como correcta " +
            "(al menos una respuesta debería tener un check amarillo)"
        );
      } else {
        sendServerError(e, "QST-UPD");
        onAction.value = false;
        return;
      }
    }
  } else if (props.mode === MODE_DUPLICATE) {
    record.value.originalQuestion = record.value.id;
    delete record.value.id;
    try {
      await create(record.value);
      question_id = recordCreate.value.id;
      message = "Pregunta duplicada correctamente";
    } catch (e) {
      if (hasIncorrectAnswer(e)) {
        sendWarnNotification(
          "¡La pregunta no tiene una respuesta correcta!",
          "<br>Revisa el apartado de respuestas y marca una como correcta " +
            "(al menos una respuesta debería tener un check amarillo)"
        );
      } else {
        sendServerError(e, "QST-DUP");
        onAction.value = false;
        return;
      }
    }
  }
  if (newImage.value) {
    try {
      await MainRepository.saveQuestionImage(question_id, newImage.value);
    } catch (e) {
      if (e.response && e.response.data && e.response.data.image) {
        imageError.value = e.response.data.image;
        scrollToSelector("#foto-form");
        sendWarnNotification("Error en la foto de la pregunta", imageError.value);
      } else {
        sendServerError(e, "QST-IMG-SAV");
      }
      onAction.value = false;
      return;
    }
  }
  if (isImageScheduledForDelete.value) {
    try {
      await MainRepository.deleteQuestionImage(question_id);
      isImageScheduledForDelete.value = false;
    } catch (e) {
      sendServerError(e, "QST-IMG-DEL");
      onAction.value = false;
      return;
    }
  }

  sendSuccessNotification("Éxito", message);
  await successNavigation();
  onAction.value = false;
}

function scrollToSelector(selector) {
  const element = document.querySelector(selector);
  if (!element) {
    console.error(`Element with selector "${selector}" not found`);
    return;
  }
  element.scrollIntoView({
    block: "center",
  });
}

function hasIncorrectAnswer(error) {
  return (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.hasOwnProperty("nonFieldErrors") &&
    error.response.data.nonFieldErrors.length > 0 &&
    error.response.data.nonFieldErrors.some((e) => e.code === "invalid_answer")
  );
}

async function successNavigation() {
  if (window.history.state.back === null) {
    router.push({ name: "home" }); //or whichever path you required
  } else {
    await router.back();
  }
}

function valueUpdated(newValue) {
  record.value.reason = newValue;
}

function changeCorrectAnswer(answerId) {
  if (allowEdit.value) {
    record.value.correctAnswer = answerId;
  }
}

function sortAnswers(from, to) {
  const item = record.value.answers.splice(from, 1)[0];
  record.value.answers.splice(to, 0, item);
  for (let i = 0; i < record.value.answers.length; i++) {
    record.value.answers[i].order = i;
  }
}

function onEnd(event) {
  sortAnswers(event.oldIndex, event.newIndex);
}

function addLaw(law) {
  if (law != null) {
    record.value.lawsData.push({ law: law });
  }
}

function removeLaw(law) {
  let index = record.value.lawsData.map((item) => item.law.id).indexOf(law.law.id);
  if (index !== -1) {
    record.value.lawsData.splice(index, 1);
  }
}

const isDiscardDialogOpen = ref(false);
const isDiscardQuestionExecuting = ref(false);

async function discardQuestion() {
  if (record.value === null) {
    return;
  }
  record.value.markAsReviewed = true;
  record.value.discarded = true;
  isDiscardQuestionExecuting.value = true;
  try {
    await submit();
  } catch (error) {
    sendServerError(error);
    isDiscardQuestionExecuting.value = true;
  } finally {
    isDiscardDialogOpen.value = false;
    // since the modal takes some time if you set isDiscardQuestionExecuting to false here the user could
    // click the button, so the button is disabled until the dialog is opened again in openPublishDialog
  }
}

const discardQuestionDialog = () => {
  isDiscardQuestionExecuting.value = false;
  if (!isQuestionMessageDiscarded.value) {
    isDiscardDialogOpen.value = true;
  } else if (record.value.deprecatedMessage.length > 0) {
    isDiscardDialogOpen.value = true;
  } else {
    isEmptyMessageDialogOpen.value = true;
    document.getElementById("impugnacion").focus();
  }
};

const adminLawUrl = import.meta.env.VITE_BACKEND_URL + "/wopo-admin/wopoedition/law/";

// admin stuff
const user = inject("user");
const adminUrl = import.meta.env.VITE_BACKEND_URL + "/wopo-admin/wopoedition/question/";
</script>
<template>
  <title-header-view>
    <template v-slot:header>
      <div class="flex flex-row items-center justify-between">
        <div>
          <p class="text-3xl">Detalle pregunta</p>
          <span v-if="examTitle" class="ml-auto text-xl">
            Examen: {{ examTitle }}
            <span v-if="examState">({{ examState }})</span>
          </span>
        </div>
        <div class="flex flex-row justify-center">
          <div class="mr-4 text-2xl">
            <p class="-mb-1">Revisada: {{ yesNoCapitalizedFormatter(isReviewed) }}</p>
            <p class="-mb-1">
              Revisión: {{ reviewStatus }}
              <base-icon
                title="Ayuda"
                icon-name="question"
                class="inline-block h-8 w-8 cursor-pointer self-end pb-1"
                @click.prevent="isDocumentationOpen = true"
              />
            </p>
          </div>
        </div>
      </div>
    </template>
    <base-card>
      <form>
        <div class="overflow-hidden bg-white">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg font-medium leading-6 text-secondary-800">{{ title }}</h3>
          </div>
          <fade-transition>
            <div v-if="record == null">
              <question-detail-dummy />
            </div>
            <div v-else>
              <div class="border-t border-secondary-200">
                <div
                  v-if="user != null && user.isSuperuser"
                  class="bg-admin px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-base font-medium text-secondary-600">Enlace a panel de administración</dt>
                  <dd>
                    <a class="underline" :href="adminUrl + record.id + '/change'">Ver en panel de administración</a>
                  </dd>
                </div>

                <div
                  v-if="record.metadata"
                  class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-base font-medium text-secondary-600">Importada de</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <p class="mb-2">
                      Pregunta nᵒ {{ record.metadata.questionNumber }} en documento:
                      <a class="underline" :href="metadataDocumentUrl" target="_blank">
                        {{ record.metadata.document.filename }}
                      </a>
                    </p>
                    <p v-if="record.metadata.startPageIndex" class="mb-2">
                      Entre las páginas {{ record.metadata.startPageIndex }} y {{ record.metadata.endPageIndex - 1 }}
                    </p>
                  </dd>
                </div>

                <div class="grid grid-cols-3 gap-4 bg-secondary-50 px-6 py-5 odd:bg-white even:bg-secondary-50">
                  <div class="col-span-3 flex">
                    <h5 class="col-span-3 text-base font-medium text-secondary-600">Oposiciones</h5>
                  </div>
                  <question-opposition-selection
                    v-model="record.oppositionsData"
                    empty-opposition-error="La pregunta necesita pertenecer a al menos una oposición"
                    class="col-span-3"
                    :allow-add="!isInExam"
                    :allow-delete="!isInExam"
                  />
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-secondary-600">Examen</dt>
                  <dd v-if="isInExam" class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <div v-for="examQuestion in record.examsData" :key="examQuestion.id">
                      <h4 class="font-bold">{{ examQuestion.exam.title }}</h4>
                      <hr v-if="examQuestion.exam.statement" />
                      <p v-if="examQuestion.exam.statement" v-html="examQuestion.exam.statement" class="mt-2"></p>
                    </div>
                  </dd>
                  <dd v-else class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <h4>Esta pregunta no pertenece a ningún examen</h4>
                  </dd>
                </div>

                <div class="px-4 odd:bg-white even:bg-secondary-50 sm:px-6">
                  <question-detail-multiple-selection
                    class="pb-0"
                    label="Leyes"
                    bg-color-class=""
                    :values="record.lawsData"
                    :value-label="(law) => law.law.shortName"
                    :select-values="laws"
                    :select-value-label="(law) => law.shortName"
                    :select-value-value="(law) => law.id"
                    empty-error-label=""
                    @remove="removeLaw"
                    @change="addLaw"
                  />
                  <p class="pb-3 text-right">
                    Puede crear nuevas leyes
                    <a-link :href="adminLawUrl">aquí</a-link>
                  </p>
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-secondary-600">Dificultad</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <base-select
                      v-model="record.difficulty"
                      id="select-valid"
                      name="difficulty"
                      :options="difficultyOptions"
                    />
                  </dd>
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-secondary-600">Subtipo</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <base-select
                      v-model="record.subtype"
                      id="select-subtype"
                      name="subtype"
                      :options="subtypeOptions"
                    />
                  </dd>
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-secondary-600">Pregunta</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <div class="fw-full lex mt-1">
                      <base-textarea
                        v-model="record.declaration"
                        id="pregunta"
                        name="'pregunta"
                        rows="3"
                        :required="true"
                        placeholder="Pregunta"
                        :disabled="!allowEdit"
                      ></base-textarea>
                    </div>
                  </dd>
                </div>

                <div
                  id="foto-form"
                  v-if="record.canUploadImage"
                  class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="flex flex-row gap-2">
                    <p class="text-base font-medium text-secondary-600">Foto</p>
                    <base-icon
                      title="Ayuda"
                      icon-name="question"
                      class="h-6 w-6 cursor-pointer"
                      alt="Ayuda"
                      @click.prevent="avatarImageTypeHelpOpen = true"
                    />
                    <base-dialog :is-open="avatarImageTypeHelpOpen">
                      <template v-slot:default>
                        <p>Puedes usar los siguientes formatos de imágenes:</p>
                        <p>{{ avatarImageType }}</p>
                      </template>
                      <template v-slot:buttons>
                        <button-primary @click="avatarImageTypeHelpOpen = false">Entendido</button-primary>
                      </template>
                    </base-dialog>
                  </dt>
                  <dd class="mt-1 flex flex-col gap-3 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <div class="mt-1 flex flex-col gap-2">
                      <base-input-document
                        v-bind="newImage"
                        ref="documentInput"
                        name="document"
                        extraInputClass="w-1/2"
                        :required="true"
                        :help-text="imageHelp"
                        @change="newImage = $event"
                        :error="imageError"
                      />
                    </div>
                    <div>
                      <img ref="imageHolder" v-if="newImage" :src="newImageURL" alt="Imagen de la pregunta" />
                      <img ref="imageHolder" v-else-if="record.image" :src="record.image" alt="Imagen de la pregunta" />
                    </div>
                    <button-link
                      v-if="newImage || record.image"
                      class="w-fit text-left"
                      @click.prevent="isDeleteOpen = true"
                    >
                      Borrar foto
                    </button-link>
                  </dd>
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-secondary-600">Respuestas</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <Sortable
                      :list="record.answers"
                      item-key="id"
                      tag="div"
                      :options="{ disabled: !allowEdit }"
                      class="divide-y divide-secondary-200 rounded-md"
                      @end="onEnd"
                    >
                      <template #item="{ element, index }">
                        <div
                          :key="element.id"
                          class="draggable group flex cursor-move items-center justify-between px-0 py-3 text-sm"
                        >
                          <div class="w-full group-[.sortable-chosen]:bg-secondary-50 sm:col-span-2">
                            <div class="flex flex-row">
                              <ArrowsUpDownIcon
                                class="text-secondary-700 mr-2 mt-1 block h-3"
                                aria-hidden="true"
                              ></ArrowsUpDownIcon>
                              <label for="'answer-'+index" class="text-secondary-700 block text-sm font-medium"
                                >Respuesta {{ index + 1 }}</label
                              >
                            </div>
                            <div class="fw-full mt-1 flex">
                              <base-textarea
                                v-model="element.declaration"
                                :id="'answer-' + index"
                                :name="'answer-' + index"
                                rows="3"
                                :required="true"
                                placeholder="Respuesta"
                                class="group-[.sortable-chosen]:bg-secondary-50"
                                :disabled="!allowEdit"
                              ></base-textarea>
                            </div>
                            <div class="fw-full mt-3 flex">
                              <div class="flex flex-row">
                                <div>Es correcta:</div>
                                <div>
                                  <check-circle-icon
                                    class="fl ml-2 h-6 w-6 cursor-pointer"
                                    :class="isCorrectClass(element.id === record.correctAnswer)"
                                    @click.prevent="changeCorrectAnswer(element.id)"
                                  />
                                </div>
                                <div>
                                  <x-circle-icon
                                    class="ml-2 h-6 w-6"
                                    :class="isIncorrectClass(element.id !== record.correctAnswer)"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </template>
                    </Sortable>
                  </dd>
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-secondary-600">Explicación</dt>
                  <dd class="my-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <rich-textarea :value="record.reason" id="reason" @value-updated="valueUpdated"></rich-textarea>
                  </dd>
                </div>

                <div class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                  <dt class="text-base font-medium text-secondary-600">Mostrar mensaje de convocatoria</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <div class="fw-full lex mt-1 flex flex-col gap-1">
                      <base-select
                        v-model="record.publicExaminationWarning"
                        id="select-show-warning"
                        name="show-warning"
                        :options="publicExaminationWarningYesNoOptions"
                        @update:model-value="publicExaminationWarningChanged"
                      />
                      <small class="text-sm text-slate-500">
                        Para incluir un mensaje de convocatoria marque esta opción como sí y aparecerá un cuadro de
                        texto debajo
                      </small>
                    </div>
                  </dd>
                </div>

                <div
                  v-if="showPublicExaminationWarningContent"
                  class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <dt class="text-base font-medium text-secondary-600">Mensaje de alarma de convocatoria</dt>
                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <div class="fw-full lex mt-1">
                      <base-textarea
                        v-model="record.publicExaminationWarningContent"
                        id="warning"
                        name="warning"
                        rows="3"
                        :required="false"
                        placeholder="Mensaje de alarma convocatoria"
                        :disabled="!allowEdit"
                      ></base-textarea>
                    </div>
                  </dd>
                </div>

                <base-dialog :is-open="isEmptyPublicExaminationWarningContent">
                  <template v-slot:header>Campo Requerido</template>
                  <template v-slot:default>
                    <div class="mb-2 ml-1 mt-3">
                      <p>
                        Debe rellenar el campo del mensaje de alarma de convocatoria si selecciona mostrar mensaje de
                        convocatoria
                      </p>
                    </div>
                  </template>
                  <template v-slot:buttons>
                    <button-secondary @click="isEmptyPublicExaminationWarningContent = false">OK</button-secondary>
                  </template>
                </base-dialog>

                <div
                  v-if="isExamPublished || record.wasPublished"
                  class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <div class="flex flex-col gap-1">
                    <p class="text-base font-medium text-secondary-600">Mostrar mensaje de impugnación</p>
                    <small>
                      Impugnación es el nuevo término para descarte cuando la pregunta ya está publicada y disponible
                      par los alumnos
                    </small>
                  </div>

                  <div class="mt-1 flex flex-col gap-1 text-base text-secondary-800 sm:col-span-2 sm:mt-0">
                    <base-select
                      v-model="discardQuestionValue"
                      id="select-repeal"
                      name="select-repeal"
                      :options="discardOptions"
                      extra-class="text-base"
                    />
                    <small class="text-sm text-slate-500">
                      Para incluir un mensaje de impugnación marque esta opción como sí y aparecerá un cuadro de texto
                      debajo
                    </small>
                  </div>
                </div>

                <div
                  v-if="isQuestionMessageDiscarded && (isExamPublished || record.wasPublished)"
                  class="px-4 py-5 odd:bg-white even:bg-secondary-50 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6"
                >
                  <div class="flex flex-col">
                    <div class="text-base font-medium text-secondary-600">Mensaje de impugnación</div>
                    <small>Antiguo mensaje de descarte</small>
                  </div>

                  <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                    <div class="fw-full lex mt-1">
                      <base-textarea
                        v-model="record.deprecatedMessage"
                        id="impugnacion"
                        name="impugnacion"
                        rows="3"
                        :required="false"
                        placeholder="Razón de impugnación"
                        :disabled="!allowEdit"
                      ></base-textarea>
                    </div>
                  </dd>
                </div>
              </div>

              <div v-if="!isReviewed" class="mt-3 flex flex-row justify-center">
                <base-button :disabled="onAction" @click.prevent="validateQuestion">
                  {{ submitButtonTitle }}
                </base-button>
                <button-discard
                  v-if="mode === MODE_EDIT"
                  class="ml-2"
                  @click.prevent="discardQuestion"
                  :disabled="onAction"
                >
                  Descartar
                </button-discard>
                <button-secondary class="ml-2" :disabled="onAction" @click.prevent="saveAndMarkQuestionsAsNotReviewed">
                  Guardar sin revisar
                </button-secondary>
                <back-button class="ml-2" :disabled="onAction">Cancelar</back-button>
                <base-icon
                  title="Ayuda"
                  icon-name="question"
                  class="ml-2 h-8 w-8 cursor-pointer self-center"
                  @click.prevent="isActionsTooltipDialogOpen = true"
                />
                <base-loading v-show="onAction" :size="6" class="ml-2 mt-1"></base-loading>
                <button-router-link
                  v-if="user && user.isSuperuser"
                  class="ml-3 bg-admin"
                  :to="{ name: 'question-preview', params: { id: record.id } }"
                  :is-secondary-button="true"
                >
                  Vista previa
                </button-router-link>
              </div>
              <div v-else-if="isDiscarded" class="mt-3 flex flex-row justify-center">
                <base-button
                  :v-if="mode === MODE_EDIT"
                  class="ml-2"
                  @click.prevent="validateQuestion"
                  :disabled="onAction"
                >
                  Validar
                </base-button>
                <button-secondary class="ml-2" :disabled="onAction" @click.prevent="submit">Guardar</button-secondary>
                <button-secondary class="ml-2" :disabled="onAction" @click.prevent="saveAndMarkQuestionsAsNotReviewed">
                  Guardar y marcar como no revisada
                </button-secondary>
                <back-button class="ml-2" :disabled="onAction">Cancelar</back-button>
                <base-icon
                  title="Ayuda"
                  icon-name="question"
                  class="ml-2 h-8 w-8 cursor-pointer self-center"
                  @click.prevent="isActionsTooltipDialogOpen = true"
                />
                <base-loading v-show="onAction" :size="6" class="ml-2 mt-1"></base-loading>
                <button-router-link
                  v-if="user && user.isSuperuser"
                  class="ml-3 bg-admin"
                  :to="{ name: 'question-preview', params: { id: record.id } }"
                  :is-secondary-button="true"
                >
                  Vista previa
                </button-router-link>
              </div>
              <div v-else class="mt-3 flex flex-row justify-center">
                <button-discard
                  v-if="!isInExam && mode === MODE_EDIT && !record.wasPublished"
                  class="ml-2"
                  @click.prevent="discardQuestion"
                  :disabled="onAction"
                >
                  Descartar
                </button-discard>
                <button-discard
                  v-if="isInExam && mode === MODE_EDIT && !isExamPublished"
                  class="ml-2"
                  @click.prevent="discardQuestion"
                  :disabled="onAction"
                >
                  Descartar
                </button-discard>
                <button-discard
                  v-else-if="(mode === MODE_EDIT && record.wasPublished) || isExamPublished"
                  class="ml-2"
                  @click.prevent="discardQuestionDialog"
                  :disabled="onAction"
                >
                  Impugnar
                </button-discard>
                <button-secondary class="ml-2" :disabled="onAction" @click.prevent="submit">Guardar</button-secondary>
                <button-secondary class="ml-2" :disabled="onAction" @click.prevent="saveAndMarkQuestionsAsNotReviewed">
                  Guardar y marcar como no revisada
                </button-secondary>
                <back-button class="ml-2" :disabled="onAction">Cancelar</back-button>
                <base-icon
                  title="Ayuda"
                  icon-name="question"
                  class="ml-2 h-8 w-8 cursor-pointer self-center"
                  @click.prevent="isActionsTooltipDialogOpen = true"
                />
                <base-loading v-show="onAction" :size="6" class="ml-2 mt-1"></base-loading>
                <button-router-link
                  v-if="user && user.isSuperuser"
                  class="ml-3 bg-admin"
                  :to="{ name: 'question-preview', params: { id: record.id } }"
                  :is-secondary-button="true"
                >
                  Vista previa
                </button-router-link>
              </div>
              <base-dialog :is-open="isActionsTooltipDialogOpen" @close-modal="isActionsTooltipDialogOpen = false">
                <template v-slot:header>Explicación de cada botón</template>
                <template v-slot:default>
                  <QuestionActionsTooltip
                    :is-in-exam="isInExam"
                    :is-discard-info-shown="!isDiscarded && mode === MODE_EDIT"
                    :is-refute-info-shown="discardQuestionBoolean && isInExam && isReviewed && !isDiscarded"
                    :is-validate-info-shown="!isReviewed || (isDiscarded && mode === MODE_EDIT)"
                    :is-save-without-reviewing-info-shown="!isReviewed"
                    :is-save-info-shown="isReviewed"
                    :is-save-and-mark-as-not-reviewed-info-shown="isReviewed"
                  />
                </template>
                <template v-slot:buttons>
                  <button-primary @click="isActionsTooltipDialogOpen = false">Entendido</button-primary>
                </template>
              </base-dialog>
            </div>
          </fade-transition>
        </div>
      </form>
    </base-card>

    <base-dialog :is-open="isDocumentationOpen">
      <template v-slot:default>
        <QuestionFlow></QuestionFlow>
      </template>
      <template v-slot:buttons>
        <button-primary @click="isDocumentationOpen = false">Entendido</button-primary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isDiscardDialogOpen">
      <template v-slot:header>Impugnar pregunta</template>
      <template v-slot:default>
        <div class="mb-2 ml-1 mt-3">
          <p v-if="isInExam">
            Esta pregunta no se impugnará para los usuarios hasta que no se republique el examen. <br />
            ¿Está seguro de que desea impugnar la pregunta en el examen <b>{{ examTitle }}</b> ?
          </p>
          <p v-else>
            Esta pregunta se eliminará automáticamente de contenido y solo estará visible para los usuarios en los
            intentos ya realizados.<br />
            ¿Está seguro de que desea impugnar la pregunta?
          </p>
        </div>
      </template>
      <template v-slot:buttons>
        <base-loading :invisible="!isDiscardQuestionExecuting" :size="6"></base-loading>
        <button-primary class="mr-2" @click="discardQuestion" :disabled="isDiscardQuestionExecuting">
          <span v-if="!isDiscardQuestionExecuting">Impugnar</span>
          <span v-else>Impugnando...</span>
        </button-primary>
        <button-secondary @click="isDiscardDialogOpen = false" :disabled="isDiscardQuestionExecuting">
          Cancelar
        </button-secondary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isEmptyMessageDialogOpen">
      <template v-slot:header>Campo Requerido</template>
      <template v-slot:default>
        <div class="mb-2 ml-1 mt-3">
          <p>
            Debe rellenar el campo del mensaje de impugnación. Si no desea establecer un mensaje de impugnación, Marque
            "Mostrar mensaje de impugnación" como no
          </p>
        </div>
      </template>
      <template v-slot:buttons>
        <button-secondary @click="isEmptyMessageDialogOpen = false">OK</button-secondary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isDeleteOpen">
      <template v-slot:header>¿Estás seguro de que quieres borrar la foto de la pregunta?</template>
      <template v-slot:default>Una vez guardada la pregunta la imagen no podrá ser recuperada.</template>
      <template v-slot:buttons>
        <button-primary class="mr-2" @click.prevent="deleteImage"> Borrar</button-primary>
        <button-secondary @click="isDeleteOpen = false">Cancelar</button-secondary>
      </template>
    </base-dialog>
  </title-header-view>
</template>
