<script setup>
import { Disclosure } from "@headlessui/vue";
import { computed, inject, nextTick, onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import MainRepository from "@/repositories/MainRepository.js";
import BaseIcon from "@/components/BaseIcon.vue";
import { useVersioning } from "@/components/composables/versioning.js";
import { useToast } from "@/components/composables/notifications.js";

const props = defineProps({
  publicExaminations: {
    type: Array,
    default: () => [],
  },
  currentAttempts: {
    type: Array,
    default: () => [],
  },
});
const user = inject("user");
const route = useRoute();
const { sendServerError } = useToast();
const { isQA } = useVersioning();

const doTestLink = computed(() => {
  return { name: "Hacer test", href: { name: "home" }, current: route.name === "home" };
});

const closeSession = async () => {
  await MainRepository.logout();
  window.location = import.meta.env.VITE_BACKEND_URL;
};

const hasNotification = ref(false);
const fetchNotifications = async () => {
  try {
    const response = await MainRepository.getPendingNotifications();
    hasNotification.value = response.data.hasPendingNotifications;
  } catch (e) {
    sendServerError(e, "ALERT-NOTIFY");
  }
};

let notificationInterval;
onMounted(async () => {
  await fetchNotifications();
  notificationInterval = setInterval(fetchNotifications, 300000); // 5min
});

onBeforeUnmount(() => {
  clearInterval(notificationInterval);
});

const adminUrl = import.meta.env.VITE_BACKEND_URL + "/wopo-admin";

const isSecondaryMenuActive = ref(false);
const toggleSecondaryMenu = () => {
  isSecondaryMenuActive.value = !isSecondaryMenuActive.value;
  const menu = document.querySelector(".test-new-menu-secondary-container");
  if (menu) {
    menu.classList.toggle("active", isSecondaryMenuActive.value);
  }
};
const toggleSecondaryMenuDesktop = () => {
  isSecondaryMenuActive.value = !isSecondaryMenuActive.value;
  const menu = document.querySelector(".test-new-menu-secondary-container");
  if (menu) {
    menu.classList.toggle("desktop-inactive", isSecondaryMenuActive.value);
  }
};

const openSection = ref(null);
const toggleSection = async (index) => {
  if (window.innerWidth > 1024) {
    expandAllSections();
    return;
  }

  const sections = document.querySelectorAll(".secondary-menu");

  if (openSection.value === index) {
    const currentSection = sections[index];
    const content = currentSection.querySelector(".secondary-menu-list");
    if (content) {
      content.style.maxHeight = "0px";
    }
    openSection.value = null;
  } else {
    openSection.value = index;
    await nextTick();

    sections.forEach((section, i) => {
      const content = section.querySelector(".secondary-menu-list");
      if (content) {
        if (i === index) {
          content.style.maxHeight = `${content.scrollHeight}px`;
        } else {
          content.style.maxHeight = "0px";
        }
      }
    });
  }
};

const expandAllSections = () => {
  const sections = document.querySelectorAll(".secondary-menu-list");
  sections.forEach((section) => {
    section.style.maxHeight = `${section.scrollHeight}px`;
  });
};

const contractAllSections = () => {
  const sections = document.querySelectorAll(".secondary-menu-list");
  sections.forEach((section) => {
    section.style.maxHeight = `0px`;
  });
};

window.addEventListener("resize", () => {
  if (window.innerWidth > 1024) {
    expandAllSections();
  } else {
    contractAllSections();
  }
});

// historical test
const firstOppositionHistoric = computed(() => {
  if (firstOpposition.value === null) {
    return "";
  }
  return { name: "public-examination-attempt-history", params: { slug: firstOpposition.value.slug } };
});

const firstOppositionStatistics = computed(() => {
  if (firstOpposition.value === null) {
    return "";
  }
  return { name: "public-examination-user-statistics", params: { slug: firstOpposition.value.slug } };
});

const firstOppositionThemeStatistics = computed(() => {
  if (firstOpposition.value === null) {
    return "";
  }
  return { name: "public-examination-themes-statistics", params: { slug: firstOpposition.value.slug } };
});

const firstOppositionLawStatistics = computed(() => {
  if (firstOpposition.value === null) {
    return "";
  }
  return { name: "public-examination-laws-statistics", params: { slug: firstOpposition.value.slug } };
});

const firstOpposition = computed(() => {
  const slug = props.publicExaminations.filter((p) => p.userHasMembership);
  if (slug.length === 0) {
    return null;
  }
  return slug[0];
});
</script>

<template>
  <Disclosure as="nav" class="main-nav sticky top-0 z-30 h-screen bg-[#F7F8FF] p-4 pr-0">
    <div class="main-new-menu-mobile flex items-center justify-between px-4 py-2 lg:hidden">
      <a class="flex gap-5" href="https://ruthgalvan.es" target="_blank">
        <img class="h-8" src="@/assets/logo-ruth-galvan-icono.svg" alt="Academia Ruth Galván logo" />
        <div v-if="isQA" class="text-center text-3xl font-bold">QA</div>
      </a>
      <button class="menu-icon rounded-xl p-3" @click="toggleSecondaryMenu">
        <base-icon class="w-full" :icon-name="isSecondaryMenuActive ? 'closemenumobile' : 'openmenumobile'" />
      </button>
    </div>
    <div class="flex h-full">
      <div
        class="main-nav-icons-menu-container sticky z-[16] mr-4 flex h-full w-20 shrink-0 flex-col items-center rounded-xl bg-white px-3 pb-3.5 pt-8"
      >
        <div class="main-nav-logo-container mb-5 hidden flex-shrink-0 lg:block">
          <p v-if="isQA" class="mb-5 text-center text-3xl font-bold">QA</p>
          <a href="https://ruthgalvan.es" target="_blank">
            <img class="h-7" src="@/assets/logo-ruth-galvan-icono.svg" alt="Academia Ruth Galván logo" />
          </a>
        </div>
        <div class="menu-icons flex flex-col items-center gap-4">
          <button
            class="menu-icon menu-icon-open-menu hidden rounded-xl p-3 md:block lg:hidden"
            @click="toggleSecondaryMenu"
          >
            <base-icon class="w-auto" icon-name="togglemenu" />
          </button>
          <button
            class="menu-icon menu-icon-open-menu hidden rounded-xl p-3 lg:block"
            @click="toggleSecondaryMenuDesktop"
          >
            <base-icon class="w-auto" icon-name="togglemenu" />
          </button>
          <router-link to="/" class="menu-icon rounded-xl p-3" :class="{ 'bg-[#EEF0FC]': route.name === 'home' }">
            <base-icon class="w-auto" icon-name="menuhome" />
          </router-link>
          <router-link
            to="/notificaciones"
            class="menu-icon rounded-xl p-3"
            :class="{ 'bg-[#EEF0FC]': route.name === 'notification-list' }"
          >
            <base-icon class="w-auto" icon-name="menunotifications" />
          </router-link>
        </div>
        <div class="menu-icons flex flex-grow flex-col items-center justify-end gap-4">
          <router-link
            v-if="user != null && user.isStaff"
            :to="{ name: 'review-tools' }"
            class="menu-icon rounded-xl p-3"
            :class="{ 'bg-[#EEF0FC]': route.name === 'review-tools' }"
          >
            <base-icon class="w-auto" icon-name="menusettings" />
          </router-link>
          <button class="menu-icon rounded-xl p-3" @click="closeSession">
            <base-icon class="w-auto" icon-name="menulogout" />
          </button>
          <router-link
            to="/perfil-de-usuario"
            class="rounded-2xl lg:mt-12"
            :class="{ 'bg-[#EEF0FC]': route.name === 'user-profile' }"
          >
            <img v-if="user?.iconAvatarUrl" :src="user.iconAvatarUrl" alt="Avatar del usuario" class="rounded-2xl" />
            <base-icon v-else icon-name="menuavatar" class="size-14 rounded-2xl bg-[#F7F8FF] md:size-16" />
          </router-link>
        </div>
      </div>
      <div class="test-new-menu-secondary-container flex h-full shrink-0 flex-grow flex-col bg-[#F7F8FF] px-4 py-6">
        <div class="secondary-menu mb-4 flex flex-col pb-4" :class="{ active: openSection === 0 }">
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(0)">
            Mis test
            <base-icon class="lg:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <router-link :to="firstOppositionHistoric" @click="toggleSecondaryMenu">Histórico test</router-link>
            <router-link :to="{ name: 'retake-test' }" @click="toggleSecondaryMenu">Retomar último test</router-link>
            <router-link :to="doTestLink.href" @click="toggleSecondaryMenu">Empezar test</router-link>
          </div>
        </div>
        <div class="secondary-menu mb-4 flex flex-col pb-4" :class="{ active: openSection === 1 }">
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(1)">
            Estadísticas
            <base-icon class="lg:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <router-link :to="firstOppositionStatistics" @click="toggleSecondaryMenu">
              Estadísticas generales
            </router-link>
            <router-link :to="firstOppositionThemeStatistics" @click="toggleSecondaryMenu">
              Estadísticas por tema
            </router-link>
            <router-link :to="firstOppositionLawStatistics" @click="toggleSecondaryMenu">
              Estadísticas por ley
            </router-link>
          </div>
        </div>
        <div class="secondary-menu mb-4 flex flex-col pb-4" :class="{ active: openSection === 2 }">
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(2)">
            Campus
            <base-icon class="lg:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <a href="https://campusruthgalvan.com/cursos/" @click="toggleSecondaryMenu">Mis cursos</a>
            <a href="https://campusruthgalvan.com/foros/" @click="toggleSecondaryMenu">Foros</a>
            <a href="https://campusruthgalvan.com/calendario-de-eventos/" @click="toggleSecondaryMenu">Calendario</a>
          </div>
        </div>
        <div class="secondary-menu mb-4 flex flex-col pb-4" :class="{ active: openSection === 3 }">
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(3)">
            Contacto
            <base-icon class="lg:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <a href="mailto:soporte@ruthgalvan.es" @click="toggleSecondaryMenu">soporte@ruthgalvan.es</a>
            <a href="tel:+34676181193" @click="toggleSecondaryMenu">676 18 11 93 - Daniel</a>
          </div>
        </div>
        <div v-if="user?.isStaff" class="secondary-menu mb-4 flex flex-col pb-4" :class="{ active: openSection === 4 }">
          <h2 class="mb-1 text-lg lg:mb-4" @click="toggleSection(4)">
            Administración
            <base-icon class="lg:hidden" icon-name="mobilemenuchevron" />
          </h2>
          <div class="secondary-menu-list flex flex-col gap-0.5 text-base md:gap-1">
            <router-link :to="{ name: 'review-tools' }" @click="toggleSecondaryMenu">
              Herramientas de revisión
            </router-link>
            <router-link :to="{ name: 'manage-users' }" @click="toggleSecondaryMenu">Usuarios</router-link>
            <router-link :to="{ name: 'staff-public-examination-statistics' }" @click="toggleSecondaryMenu">
              Estadísticas generales
            </router-link>
            <a :href="adminUrl" @click="toggleSecondaryMenu">Panel administración</a>
          </div>
        </div>
      </div>
    </div>
  </Disclosure>
</template>
