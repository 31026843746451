<script setup>
const props = defineProps({
  isInExam: {
    type: Boolean,
    required: true,
  },
  isDiscardInfoShown: {
    type: Boolean,
    default: false,
  },
  isRefuteInfoShown: {
    type: Boolean,
    default: false,
  },
  isSaveInfoShown: {
    type: Boolean,
    default: true,
  },
  isSaveWithoutReviewingInfoShown: {
    type: Boolean,
    default: false,
  },
  isSaveAndMarkAsNotReviewedInfoShown: {
    type: Boolean,
    default: true,
  },
  isValidateInfoShown: {
    type: Boolean,
    default: false,
  },
  isCancelInfoShown: {
    type: Boolean,
    default: true,
  },
});
</script>

<template>
  <div v-if="isInExam" class="text-left">
    <p v-if="props.isValidateInfoShown" class="my-2">
      <b>Validar: </b>marca la pregunta como válida. Cuando el examen se publique esta pregunta se mostrará a los
      alumnos
    </p>
    <p v-if="props.isDiscardInfoShown" class="my-2">
      <b>Descartar: </b>marca la pregunta como descartada. Cuando el examen se publique esta pregunta no se mostrará a
      los alumnos
    </p>
    <p v-if="props.isRefuteInfoShown" class="my-2">
      <b>Impugnar: </b>impugna una pregunta de un examen ya publicado. Esta acción no marcará la pregunta como
      descartada has que se vuelva a sincronizar el exámen.
    </p>
    <p v-if="props.isSaveInfoShown" class="my-2"><b>Guardar: </b>guarda la pregunta</p>
    <p v-if="props.isSaveWithoutReviewingInfoShown" class="my-2">
      <b>Guardar sin revisar: </b>guarda la pregunta sin marcarla como revisada
    </p>
    <p v-if="props.isSaveAndMarkAsNotReviewedInfoShown" class="my-2">
      <b>Guardar y marcar como No Revisada: </b>guarda la pregunta y la marca como no revisada
    </p>
    <p v-if="props.isCancelInfoShown" class="my-2">
      <b>Cancelar: </b>salir sin guardar los cambios realizados en la pregunta
    </p>
  </div>

  <div v-else class="text-left">
    <p v-if="props.isValidateInfoShown" class="my-2">
      <b>Validar: </b>marca la pregunta como válida en el banco de preguntas. Cuando se sincronicen las pregunta esta se
      mostrará a los alumnos.
    </p>
    <p v-if="props.isDiscardInfoShown" class="my-2">
      <b>Descartar: </b>marca la pregunta como descartada en el banco de preguntas. Cuando se sincronicen las pregunta
      esta pregunta NO se mostrará a los alumnos
    </p>
    <p v-if="props.isRefuteInfoShown" class="my-2">
      <b>Impugnar: </b>impugna una pregunta del banco de preguntas. Esta acción tiene efecto inmediato.
    </p>
    <p v-if="props.isSaveInfoShown" class="my-2"><b>Guardar: </b>guarda la pregunta</p>
    <p v-if="props.isSaveWithoutReviewingInfoShown" class="my-2">
      <b>Guardar sin revisar: </b>guarda la pregunta sin marcarla como revisada
    </p>
    <p v-if="props.isSaveAndMarkAsNotReviewedInfoShown" class="my-2">
      <b>Guardar y marcar como No Revisada: </b>guarda la pregunta y la marca como no revisada
    </p>
    <p v-if="props.isCancelInfoShown" class="my-2">
      <b>Cancelar: </b>salir sin guardar los cambios realizados en la pregunta
    </p>
  </div>
</template>
