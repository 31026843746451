<script setup>
import MainRepository from "@/repositories/MainRepository.js";
import { inject, onMounted, ref } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import { useRouter } from "vue-router";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";
import ButtonTertiary from "@/components/buttons/ButtonTertiary.vue";
import TheLoading from "@/components/TheLoading.vue";
import ButtonPrimaryWithArrow from "@/components/buttons/ButtonPrimaryWithArrow.vue";
import PageTitle from "@/components/PageTitle.vue";

const { sendServerError } = useToast();
const router = useRouter();

onMounted(getAttempts);

const publicExaminations = inject("publicExaminations");
const isLoading = ref(true);
const attempts = ref([]);

async function getAttempts() {
  isLoading.value = true;
  attempts.value = [];

  const promises = publicExaminations.value.map((x) => MainRepository.attemptInProgress({ slug: x.slug }));
  try {
    const responses = await Promise.all(promises);
    for (const response of responses) {
      if (response.data.uuid) {
        attempts.value.push(response.data);
      }
    }
  } catch (error) {
    sendServerError(error);
  }

  if (attempts.value.length === 1) {
    await router.replace({ name: "in-attempt", params: { uuid: attempts.value[0].uuid } });
    return;
  }
  isLoading.value = false;
}
</script>

<template>
  <the-loading v-if="isLoading"></the-loading>
  <no-header-view v-else>
    <div class="flex flex-col" v-if="attempts.length === 0">
      <page-title>No tienes test pendientes</page-title>
      <router-link :to="{ name: 'home' }">
        <button-primary-with-arrow>Empezar test</button-primary-with-arrow>
      </router-link>
    </div>
    <page-title v-else>Tienes los siguientes test pendientes, ¿cuál quieres continuar?</page-title>
    <p v-for="attempt in attempts" :key="attempt.publicExamination" class="mb-3 flex items-center gap-3 text-xl">
      {{ publicExaminations.filter((x) => x.id === attempt.publicExamination)[0].shortName }}
      <router-link :to="{ name: 'in-attempt', params: { uuid: attempt.uuid } }">
        <button-tertiary> Continuar intento</button-tertiary>
      </router-link>
    </p>
  </no-header-view>
</template>
